import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import './MitemStatus.less';
import { MitemStatus } from '../generated/graphql';

import { CheckOutlined } from '@ant-design/icons';

interface Props {
  mitemStatus: MitemStatus;
  compact?: boolean;
  checkIcon?: boolean;
}

export const MitemTag = ({ mitemStatus, compact, checkIcon }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx('MitemStatus', `MitemStatus--${mitemStatus}`, {
        'MitemStatus--compact': compact,
      })}
    >
      {checkIcon && <CheckOutlined className="mr--s" />}
      {t('MitemStatus.' + mitemStatus)}
    </div>
  );
};
