import { useQuery, useMutation, gql } from '@apollo/client';
import {
  AchievementStatus,
  DeleteAchievementDocument,
  DeleteMitemAchievementDocument,
  GetUserAchievementsDocument,
  MitemStatus,
  TeamKeyActivityCommitmentAndAchievementsForPeriodDocument,
  TimePeriodFilter,
  UserAkpiAchievementFragment,
  UserMitemAchievementFragment,
} from '../../../../generated/graphql';
import { Table, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { stringSort } from '../../../../services/stringSort';
import { SectionHeader } from '../../../../components/SectionHeader';
import { MitemTag } from '../../../../components/MitemStatus';
import { EditWeeklyAchievementModal } from './EditWeeklyAchievementModal';
import { EditMitemAchievementModal } from './EditMitemAchievementModal';
import { ErrorAlert } from '../../../../components/ErrorAlert';
import { TEAM_COMMITMENTS_AND_ACHIEVEMENTS } from '../../../../hooks/useTeamCommitmentAndAchievementV2';
import { useMe } from '../../../../hooks/useMe';
import { useAuth0 } from '../../../../Auth0Provider';
import { showNotification } from '../../../../services/fetchNotificationProperties';
import { REPORT_DRAWER_2_QUERY } from '../../../../components/Navigation/ReportDrawer/ReportDrawer';
import { DeleteOutlined } from '@ant-design/icons';
import './MyAchievementsCard.less';
import { friendlyDate } from '../../../../services/dateFormats';

interface Props {
  userId: string;
  achievementStatus?: AchievementStatus | null;
  title: string;
  tenantId?: string;
}

const AKPI_ACHIEVEMENT_TYPE = 'AkpiAchievement';

export const MyAchievementsCard = ({
  userId,
  tenantId,
  achievementStatus,
  title,
}: Props) => {
  const { t } = useTranslation();
  const { isAdmin } = useMe();
  const { user } = useAuth0();
  const permissionToChangePreviousPeriods =
    isAdmin && user?.howwe.userId !== userId;

  const { data, loading, error } = useQuery(GetUserAchievementsDocument, {
    variables: {
      userId,
      tenantId,
      achievementStatus,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [deleteRepetitiveUserAchievement] = useMutation(
    DeleteAchievementDocument,
    {
      onError: () => {
        showNotification('error', {
          message: t('MyAchievementsCard.deleteError'),
        });
      },
      refetchQueries: (res) => {
        if (!res.data?.deleteAchievement) return [];
        return [
          {
            query: GetUserAchievementsDocument,
            variables: {
              userId,
              tenantId,
              achievementStatus,
            },
          },
          {
            query: TeamKeyActivityCommitmentAndAchievementsForPeriodDocument,
            variables: {
              teamId: res.data.deleteAchievement.team.id,
              timePeriodFilter: TimePeriodFilter.CURRENT,
            },
          },
          { query: REPORT_DRAWER_2_QUERY },
        ];
      },
      onCompleted: (data) => {
        showNotification('success', {
          message: (
            <span>
              {t('MyAchievementsCard.deleteSuccess', {
                teamName: data.deleteAchievement.team.name,
                akpiName: data.deleteAchievement.akpi.name,
                quantity: data.deleteAchievement.quantity,
                akpiUnit: data.deleteAchievement.akpi.unit,
              })}
            </span>
          ),
        });
      },
    }
  );

  const [deleteMitemUserAchievement] = useMutation(
    DeleteMitemAchievementDocument,
    {
      onError: () => {
        showNotification('error', {
          message: t('MyAchievementsCard.deleteError'),
        });
      },
      refetchQueries: (res) => {
        if (!res.data?.deleteMitemAchievement) return [];
        return [
          {
            query: USER_ACHIEVEMENTS,
            variables: {
              userId,
              achievementStatus,
            },
          },
          {
            query: TEAM_COMMITMENTS_AND_ACHIEVEMENTS,
            variables: {
              teamId: res.data.deleteMitemAchievement.team.id,
              timePeriodFilter: TimePeriodFilter.CURRENT,
            },
          },
          { query: REPORT_DRAWER_2_QUERY },
        ];
      },
      onCompleted: (data) => {
        showNotification('success', {
          message: (
            <span>
              {data.deleteMitemAchievement.mitem.name}
              {t('MyAchievementsCard.deleteMitemSuccess', {
                teamName: data.deleteMitemAchievement.team.name,
                mitemName: data.deleteMitemAchievement.mitem.name,
              })}
            </span>
          ),
        });
      },
    }
  );

  const userAchievementsColumns: ColumnProps<
    UserAkpiAchievementFragment | UserMitemAchievementFragment
  >[] = [
    {
      title: t('MyAchievementsCard.dateColumn'),
      dataIndex: 'created',
      key: 'created',
      width: 150,
      defaultSortOrder: 'descend',
      sorter: (a, b) => stringSort(a.created, b.created),
      render: (_, { created }) => friendlyDate(created),
    },
    {
      title: t('MyAchievementsCard.teamColumn'),
      dataIndex: ['team', 'name'],
      ellipsis: true,
    },
    {
      title: t('MyAchievementsCard.akpiColumn'),
      dataIndex: ['akpi', 'name'],
      ellipsis: true,
      render: (text: string, achievement) => {
        if (achievement.__typename === AKPI_ACHIEVEMENT_TYPE) {
          return <span>{text}</span>;
        }
        return (
          <>
            <span>{achievement.mitem.name}</span>
          </>
        );
      },
    },
    {
      title: t('MyAchievementsCard.amountColumn'),
      dataIndex: 'quantity',
      ellipsis: true,
      render: (text: string, achievement) => {
        if (achievement.__typename === AKPI_ACHIEVEMENT_TYPE) {
          return (
            <span>
              {text} {achievement?.akpi?.unit}
            </span>
          );
        }
        return (
          <>
            <span>
              {achievement.completed ? (
                <MitemTag
                  checkIcon
                  compact
                  mitemStatus={MitemStatus.COMPLETED}
                />
              ) : (
                t('MyAchievementsCard.inProgress')
              )}
            </span>
          </>
        );
      },
    },
    {
      title: <div>{t('MyAchievementsCard.commentColumn')}</div>,
      dataIndex: 'description',
      ellipsis: true,
      render: (text: string) => {
        return (
          <div>
            <span className="txt--secondary">{text}</span>
          </div>
        );
      },
    },
  ];

  if (
    achievementStatus === AchievementStatus.DELETABLE_BY_USER ||
    permissionToChangePreviousPeriods
  ) {
    userAchievementsColumns.push({
      title: t('common.actions'),
      key: 'actions',
      width: 100,
      render: (_, achievement) => {
        if (achievement.__typename === AKPI_ACHIEVEMENT_TYPE) {
          return (
            <>
              <Popconfirm
                title={t('MyAchievementsCard.confirmDelete', {
                  teamName: achievement.team.name,
                  akpiName: achievement.akpi?.name,
                  quantity: achievement.quantity,
                  akpiUnit: achievement.akpi?.unit,
                })}
                placement="topRight"
                onConfirm={() => {
                  deleteRepetitiveUserAchievement({
                    variables: { achievementId: achievement.id, tenantId },
                  });
                }}
                okText={t('common.confirm')}
                cancelText={t('common.cancel')}
              >
                <DeleteOutlined className="UserAchievementsCard__deleteButton mr--xl ml" />
              </Popconfirm>
              <EditWeeklyAchievementModal
                achievement={achievement}
                akpi={achievement.akpi}
                tenantId={tenantId}
              ></EditWeeklyAchievementModal>
            </>
          );
        }
        return (
          <>
            <Popconfirm
              title={t('MyAchievementsCard.confirmMitemDelete', {
                teamName: achievement.team.name,
                mitemName: achievement.mitem?.name,
              })}
              placement="topRight"
              onConfirm={() => {
                deleteMitemUserAchievement({
                  variables: { achievementId: achievement.id, tenantId },
                });
              }}
              okText={t('common.confirm')}
              cancelText={t('common.cancel')}
            >
              <DeleteOutlined className="MyAchievementsCard__deleteButton mr--xl ml" />
            </Popconfirm>
            <EditMitemAchievementModal
              achievement={achievement}
              tenantId={tenantId}
            ></EditMitemAchievementModal>
          </>
        );
      },
    });
  }

  const userAchievementData = data?.akpiAchievementsForUser ?? [];

  return (
    <>
      <ErrorAlert title={t('MyAchievementsCard.fetchError')} error={error} />

      <SectionHeader title={title}>
        <Table
          size="middle"
          loading={loading}
          dataSource={userAchievementData}
          columns={userAchievementsColumns}
          rowKey={(achievement) => achievement.id}
          pagination={
            achievementStatus === AchievementStatus.DELETABLE_BY_USER
              ? false
              : {}
          }
          locale={{
            emptyText: loading ? ' ' : t('MyAchievementsCard.noAchievements'),
          }}
        ></Table>
      </SectionHeader>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const USER_AKPI_ACHIEVEMENT_FRAGMENT = gql`
  fragment UserAkpiAchievement on AkpiAchievement {
    akpi {
      id
      name
      unit
      acceptanceCriteriaHtml
      achievementDescriptionMandatory
    }
    description
    created
    quantity
    id
    team {
      id
      name
    }
    userId
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const USER_MITEM_ACHIEVEMENT_FRAGMENT = gql`
  fragment UserMitemAchievement on MitemAchievement {
    mitem {
      id
      name
      completed
      definitionOfDone
      deadline
    }
    permittedToComplete
    latestAchievement
    description
    created
    completed
    id
    team {
      id
      name
    }
  }
`;

export const USER_ACHIEVEMENTS = gql`
  query getUserAchievements(
    $userId: ID!
    $achievementStatus: AchievementStatus
    $tenantId: ID
  ) {
    akpiAchievementsForUser(
      userId: $userId
      achievementStatus: $achievementStatus
      tenantId: $tenantId
    ) {
      ...UserAkpiAchievement

      ...UserMitemAchievement
    }
  }
  ${USER_AKPI_ACHIEVEMENT_FRAGMENT}
  ${USER_MITEM_ACHIEVEMENT_FRAGMENT}
`;

export const DELETE_REPETITIVE_ACHIEVEMENT = gql`
  mutation deleteAchievement($achievementId: ID!, $tenantId: ID) {
    deleteAchievement(achievementId: $achievementId, tenantId: $tenantId) {
      id
      akpi {
        id
        name
        unit
      }
      quantity
      team {
        id
        name
      }
    }
  }
`;

export const DELETE_MITEM_ACHIEVEMENT = gql`
  mutation deleteMitemAchievement($achievementId: ID!, $tenantId: ID) {
    deleteMitemAchievement(achievementId: $achievementId, tenantId: $tenantId) {
      id
      mitem {
        id
        name
      }
      team {
        id
        name
      }
    }
  }
`;
