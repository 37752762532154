import React, { useState, useContext, createContext } from 'react';
import { useAuth0 } from './Auth0Provider';

interface Context {
  tenantId?: string | null;
  isOverridden: boolean;
  changeTenantIdAndReload: (tenantId: string) => void;
  revertToUserTenantAndReload: () => void;
}

export const TenantOverrideContext = createContext<Context>({} as Context);
export const useTenantOverride = () => useContext(TenantOverrideContext);

const STORAGE_KEY = 'tenantIdOverride';

export const TenantOverrideProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [tenantIdOverride] = useState(getOverrideId);
  const { user } = useAuth0();

  const changeTenantIdAndReload = (tenantId: string) => {
    if (tenantId === user?.howwe.tenantId) {
      localStorage.removeItem(STORAGE_KEY);
    } else {
      localStorage.setItem(STORAGE_KEY, tenantId);
    }

    window.location.href = '/';
  };

  const revertToUserTenantAndReload = () => {
    localStorage.removeItem(STORAGE_KEY);
    window.location.href = '/';
  };

  return (
    <TenantOverrideContext.Provider
      value={{
        tenantId: tenantIdOverride || user?.howwe.tenantId,
        isOverridden: !!(
          tenantIdOverride && tenantIdOverride !== user?.howwe.tenantId
        ),
        changeTenantIdAndReload,
        revertToUserTenantAndReload,
      }}
    >
      {children}
    </TenantOverrideContext.Provider>
  );
};

const getOverrideId = () => {
  const match = window.location.search.match(
    /[?&]tenantId=(?<tenantId>[a-z0-9-]+)/i
  );
  const queryTenantIdOverride = match && (match.groups?.tenantId || match[1]); // the right side of the OR is because of IEs lack of named capturing groups

  if (queryTenantIdOverride) {
    localStorage.setItem(STORAGE_KEY, queryTenantIdOverride);
    return queryTenantIdOverride;
  }

  return localStorage.getItem(STORAGE_KEY);
};
