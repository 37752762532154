import { useTranslation } from 'react-i18next';
import { Graph } from '../../../../Graph';
import { TeamOverviewGraphLegends } from '../../../../TeamOverviewGraphLegends';
import { SkaCard } from './SkaCard';
import { Period } from '../../../../../../../../services/GraphTickHelper';
import {
  friendlyDate,
  standardDateFormat,
} from '../../../../../../../../services/dateFormats';
import {
  GetSkaPerformanceForTeamDocument,
  MitemPeriod,
  SkaGraphSkaTimeStatusFragment,
  SkaGraph_MitemTermFragment,
} from '../../../../../../../../generated/graphql';
import { accumulatePeriodData } from '../../../../../../../../services/akpiUtils';
import dayjs from 'dayjs';
import { useLazyTeamTimeline } from '../../../../../../../../hooks/useTeamTimeline';
import { useEffect } from 'react';
import { sprintTermDTOtoSprintTermData } from '../../../../../../../../services/sprintKeyActivityUtils';
import { SelectedSprint } from './SprintSelector';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  calculateDoneVsPlan,
  getCompletedAtSkasBetweenDates,
} from '../../../../../../common/utils/teamPageHelpers';
import { useClosestPeriods } from '../../../../../../../../hooks/useClosestPeriods';

interface Props {
  teamId: string;
  selectedSprint: SelectedSprint | null;
  sprintKeyActivities?: SkaGraphSkaTimeStatusFragment[];
  currentSprintTerm: SkaGraph_MitemTermFragment;
  width?: number;
}

export const SkaGraph = ({
  teamId,
  selectedSprint,
  sprintKeyActivities,
  currentSprintTerm,
  width,
}: Props) => {
  const { t } = useTranslation();
  const { getTimeline, timeline } = useLazyTeamTimeline();
  const { currentPeriod } = useClosestPeriods(teamId);

  const { data } = useSuspenseQuery(GetSkaPerformanceForTeamDocument, {
    variables: { teamId },
  });

  const skaPerformance =
    data?.keyActivityTargetFulfillmentForTeam?.details.find(
      (ka) => ka.type === 'TERM'
    );

  useEffect(() => {
    if (selectedSprint) {
      getTimeline(teamId, selectedSprint.startDate, selectedSprint.endDate);
    }
  }, [getTimeline, teamId, selectedSprint]);

  const byDeadline: Record<
    string,
    { goal: number; actual: number; targetDate: string; label: string }
  > = {};

  const sprintTerm =
    currentSprintTerm && sprintTermDTOtoSprintTermData(currentSprintTerm);

  for (const period of timeline ?? []) {
    const deadline = standardDateFormat(period.timePeriodEndDate);
    byDeadline[deadline] = {
      goal: 0,
      actual: 0,
      targetDate: deadline,
      label: friendlyDate(deadline),
    };
  }

  if (sprintKeyActivities) {
    //Creating periodData for graph (for active, planned & finalized sprint)
    for (const ska of sprintKeyActivities) {
      const current = byDeadline[ska.deadline];
      //Need this because it crashed when timeline wasn't updated
      if (current) {
        current.goal = current.goal + 1;
      }
      byDeadline[ska.deadline] = current;
    }
  }

  let periodData: Period[] = [];

  if (timeline) {
    periodData =
      timeline?.map(({ timePeriodEndDate, timePeriodStartDate }) => {
        return {
          ...byDeadline[standardDateFormat(timePeriodEndDate)],
          actual: getCompletedAtSkasBetweenDates(
            sprintKeyActivities ?? [],
            standardDateFormat(timePeriodStartDate),
            standardDateFormat(timePeriodEndDate)
          ).length,
        };
      }) ?? [];
  }

  const accumulatedPeriodData = accumulatePeriodData(periodData).map(
    (period: Period) => {
      if (
        dayjs(period.targetDate).isAfter(
          dayjs(currentPeriod?.timePeriodEndDate)
        )
      ) {
        period.actual = null;
      }
      return period;
    }
  );

  //To create data for graph legends
  const latestFinalizedPeriod = accumulatedPeriodData?.reduce(
    (latestFinalized, period) => {
      const periodTargetDateIsBeforeToday = dayjs(period.targetDate).isBefore(
        dayjs(),
        'day'
      );

      if (period.actual != null && periodTargetDateIsBeforeToday) {
        return period;
      }
      return latestFinalized;
    },
    undefined as Period | undefined
  );

  const latestFinalizedPeriodTerm = sprintTerm.periodData?.reduce(
    (latestFinalized, period) => {
      const periodTargetDateIsBeforeToday = dayjs(period.targetDate).isBefore(
        dayjs(),
        'day'
      );
      if (period.actual != null && periodTargetDateIsBeforeToday) {
        return period;
      }
      return latestFinalized;
    },
    undefined as MitemPeriod | undefined
  );

  const isEndedSprint = selectedSprint?.finalized === true;

  const doneVsPlanEndedSprint = isEndedSprint
    ? calculateDoneVsPlan(
        latestFinalizedPeriod?.actual ?? 0,
        latestFinalizedPeriod?.goal ?? 0
      )
    : null;

  const doneVsPlanTerm =
    skaPerformance != null ? skaPerformance.targetFulfillment : null;

  const doneVsPlan =
    selectedSprint === null ? doneVsPlanTerm : doneVsPlanEndedSprint;

  return (
    <SkaCard>
      <h3 className="mb--xl" data-intercom-target="SKA graph header">
        {t('SkaGraph.progressOverTime')}
      </h3>
      <Graph
        periodData={
          selectedSprint === null
            ? sprintTerm.periodData
            : accumulatedPeriodData
        }
        startDate={
          selectedSprint === null
            ? sprintTerm.startDate
            : selectedSprint.startDate
        }
        width={width}
        referenceLine={
          selectedSprint === null
            ? latestFinalizedPeriodTerm?.targetDate
            : latestFinalizedPeriod?.targetDate
        }
        showDots={selectedSprint === null ? false : true}
      >
        <TeamOverviewGraphLegends
          performance={doneVsPlan}
          goal={
            selectedSprint === null
              ? latestFinalizedPeriodTerm?.goal
              : latestFinalizedPeriod?.goal
          }
          progress={
            selectedSprint === null
              ? latestFinalizedPeriodTerm?.actual
              : latestFinalizedPeriod?.actual
          }
        />
      </Graph>
    </SkaCard>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SKA_GRAPH_FRAGMENT = gql`
  fragment SkaGraphSkaTimeStatus on SkaTimeStatus {
    id
    status
    deadline
    sprintKeyActivity {
      completedAt
    }
  }

  fragment SkaGraph_MitemTerm on MitemTerm {
    id
    startDate
    periodData {
      goal
      actual
      targetDate
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SKA_PERFORMANCE = gql`
  query getSkaPerformanceForTeam($teamId: ID!, $tenantId: ID) {
    keyActivityTargetFulfillmentForTeam(teamId: $teamId, tenantId: $tenantId) {
      details {
        id
        type
        targetFulfillment
      }
    }
  }
`;
