import { Divider } from 'antd';
import { GlobalMigSection } from './components/GlobalMigSection/GlobalMigSection';

import { Suspense } from 'react';
import { InitiativeSection } from './components/InitiativeSection/InitiativeSection';

export const StrategicPlanPage = () => {
  return (
    <div className="content mt">
      <Suspense fallback={<GlobalMigSection.Skeleton />}>
        <GlobalMigSection />
      </Suspense>
      <Divider />
      <Suspense fallback={<InitiativeSection.Skeleton />}>
        <InitiativeSection />
      </Suspense>
    </div>
  );
};
