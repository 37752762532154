import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { DisplayDate } from '../../../../../../../components/DisplayDate';
import { SkaDetailsContent_MitemFragment } from '../../../../../../../generated/graphql';
import { DisplayName } from '../../../../../../../components/DisplayName';
import { stringSort } from '../../../../../../../services/stringSort';
import { InitiativeTag } from '../../../../../../../components/initiative/InitiativeTag';
import {
  Icons,
  InitiativeIcon,
} from '../../../../../../company/initiatives_old/initiativesPageV1/InitiativeIcons';
import { TagImage } from '../../../../../setup/overviewPage/labels/TagImage';
import { gql } from '@apollo/client';
import './SkaDetailsContent.less';

interface Props {
  fullSKa?: SkaDetailsContent_MitemFragment;
}

export const SkaDetailsContent = ({ fullSKa }: Props) => {
  const { t } = useTranslation();

  const teamTags = fullSKa?.tags ?? [];

  const sortedTeamTags = [...teamTags].sort((a, b) => {
    return stringSort(a.name, b.name);
  });

  const supportedInitiatives = fullSKa?.supportedInitiatives ?? [];

  const sortedInitiativeTags = [...supportedInitiatives].sort((a, b) => {
    return stringSort(a.tag.title, b.tag.title);
  });

  const supportMigs = fullSKa?.supportedMigs ?? [];
  const hasMigAlignment = supportMigs.length > 0;

  return (
    <div className="SkaDetailsContent__tabContent">
      <Typography.Title type="secondary" level={5} className="mb--none">
        {t('common.title')}
      </Typography.Title>
      <Typography.Text strong>{fullSKa?.name}</Typography.Text>
      <Typography.Title type="secondary" level={5} className="mb--none mt--xl">
        {t('SkaDetailsContent.deadlineLabel')}
      </Typography.Title>
      <Typography.Text>
        <DisplayDate date={fullSKa?.deadline} alwaysIncludeYear />
      </Typography.Text>
      <Typography.Title type="secondary" level={5} className="mb--none mt--xl">
        {hasMigAlignment
          ? t('SkaDetailsContent.migLabel')
          : t('SkaDetailsContent.noMigRelation')}
      </Typography.Title>
      <Typography.Text>
        {hasMigAlignment &&
          fullSKa?.supportedMigs?.map((mig) => (
            <div key={mig.id}>{mig.name}</div>
          ))}
        {!hasMigAlignment && fullSKa?.noMigAssociation}
      </Typography.Text>
      <Typography.Title type="secondary" level={5} className="mb--none mt--xl">
        {t('SkaDetailsContent.ownerLabel')}
      </Typography.Title>
      <Typography.Text>
        {fullSKa?.owner && <DisplayName user={fullSKa?.owner} />}
      </Typography.Text>
      <Typography.Title type="secondary" level={5} className="mb--none mt--xl">
        {t('SkaDetailsContent.dodLabel')}
      </Typography.Title>
      <Typography.Paragraph style={{ whiteSpace: 'pre-wrap' }}>
        {fullSKa?.definitionOfDone}
      </Typography.Paragraph>

      <>
        <Typography.Title
          type="secondary"
          level={5}
          className="mb--none mt--xl"
        >
          {t('SkaDetailsContent.tags')}
        </Typography.Title>
        <div className="flx flx--gap--xs flx--wrap">
          {sortedInitiativeTags.map(({ id, tag, completed, archived }) => (
            <InitiativeTag
              key={id}
              title={tag.title}
              borderColor={tag.colorCode}
              completed={completed?.value}
              icon={Icons[tag.iconId as InitiativeIcon]}
              archived={archived}
            />
          )) ?? []}
          {sortedTeamTags.map((t) => <TagImage key={t.id} tag={t} />) ?? []}
        </div>
      </>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SKA_DETAILS_CONTENT_FRAGMENT = gql`
  fragment SkaDetailsContent_Mitem on Mitem {
    id
    name
    teamId
    definitionOfDone
    deadline
    noMigAssociation
    tags {
      id
      name
      backgroundColor
    }
    supportedInitiatives {
      id
      completed {
        value
      }
      archived
      tag {
        title
        colorCode
        iconId
      }
    }
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
    owner {
      id
      email
      name
      displayName
    }
  }
`;
