import { gql, useQuery } from '@apollo/client';
import {
  GetNextSprintOverviewDocument,
  MostImportantGoalInsightsDocument,
} from '../generated/graphql';

export const sharedInsightKeys = [
  'KA_NEXT_SPRINT',
  'MIG__ACTUAL',
  'KA_SETUP__ENDING',
  'MIG__SETUP__ENDING',
  'SKA__SETUP__SPRINT_TERM_ENDING',
];

export function getInsightCode(code: string): string {
  return sharedInsightKeys.find((key) => code.includes(key)) ?? 'default';
}

export function generateArgumentsMap(args: { name: string; value: string }[]) {
  return args.reduce(
    (acc, { name, value }) => ({
      ...acc,
      [name]: value,
    }),
    {} as Record<string, string>
  );
}

export type GenericInsights = {
  code: string;
  args: {
    name: string;
    value: string;
  }[];
};

export type ActualInsight = {
  code: string;
  migsToShow: {
    state: string;
    missingPeriods: (string | null)[];
    id: string;
    name: string;
    domainId: {
      itemId: string;
      teamId: string;
    };
  }[];
};

export type MigCustomInsights = {
  actual: ActualInsight | null;
};

export const useNextSprintInsights = (teamId: string) => {
  const { data, error, loading } = useQuery(GetNextSprintOverviewDocument, {
    variables: { teamId },
    fetchPolicy: 'cache-and-network',
  });
  const genericInsights = data?.nextSprintOverview?.genericInsights ?? [];
  return {
    nextSprintInsights: {
      genericInsights: genericInsights as GenericInsights[],
    },
    loading,
    error,
  };
};

export const useMostImportantGoalsInsights = (teamId: string) => {
  const { data, loading, error } = useQuery(MostImportantGoalInsightsDocument, {
    variables: { teamId },
    fetchPolicy: 'cache-and-network',
  });
  const genericInsights =
    data?.mostImportantGoalInsights?.genericInsights ?? [];
  const actual = data?.mostImportantGoalInsights?.actual ?? null;

  return {
    migInsights: {
      genericInsights: genericInsights as GenericInsights[],
      customInsights: { actual } as MigCustomInsights,
    },
    error,
    loading,
  };
};

export const NEXT_SPRINT_INSIGHTS = gql`
  query GetNextSprintOverview($teamId: ID!) {
    nextSprintOverview(teamId: $teamId) {
      genericInsights {
        code
        args {
          name
          value
        }
      }
    }
  }
`;

export const MOST_IMPORTANT_GOALS_INSIGHTS = gql`
  query mostImportantGoalInsights($teamId: ID!) {
    mostImportantGoalInsights(teamId: $teamId) {
      actual {
        code
        migsToShow {
          state
          missingPeriods
          id
          name
          domainId {
            itemId
            teamId
          }
        }
      }
      genericInsights {
        code
        args {
          name
          value
        }
      }
    }
  }
`;
