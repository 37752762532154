import { useQuery, gql } from '@apollo/client';
import { Card, Layout } from 'antd';
import { GetUserPageDocument } from '../../generated/graphql';
import { MySettingsPage } from './settings/MySettingsPage';
import { useTranslation } from 'react-i18next';
import { MyAchievementsPage } from './achievements/MyAchievementsPage';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ContextualNavbarContent } from '../../components/Navigation/ContextualNavbarContent/ContextualNavbarContent';
import { SectionHeader } from '../../components/SectionHeader';
import { DisplayName } from '../../components/DisplayName';
import React from 'react';
import { envConf } from '../../envConf';
import { useMyPermissionRoles } from '../../usePermissions';

const tabRoutes = {
  SETTINGS: 'settings',
  KA_REPORTS: 'achievements',
};
const DemoGenerationHelper = React.lazy(() =>
  import('../../components/DemoGenerationHelper').then(
    ({ DemoGenerationHelper }) => ({
      default: DemoGenerationHelper,
    })
  )
);

const isTestEnvironment =
  envConf.APP_ENV === 'integration' || envConf.APP_ENV === 'demo';

export const MyPage = () => {
  const { isSuperAdmin, isTenantAdmin } = useMyPermissionRoles();
  const { t } = useTranslation();
  const { data, error } = useQuery(GetUserPageDocument);

  if (error) return <h2>{t('MyPage.fetchUserError')}</h2>; // Todo: Set error message
  if (!data) return null;

  const { me: user } = data;

  const isAllowedToGenerateData = isSuperAdmin || isTenantAdmin;

  return (
    <>
      <ContextualNavbarContent
        pageTitle={<DisplayName user={user} />}
        menuItems={[
          {
            title: t('MyPage.akpiResultsTab'),
            url: tabRoutes.KA_REPORTS,
            intercomDataTarget: 'User Key Activity Reports Navigation',
          },
          {
            title: t('MyPage.settingsTab'),
            url: tabRoutes.SETTINGS,
            intercomDataTarget: 'User Settings Page Navigation',
          },
        ]}
      />
      <Layout.Content
        className="content"
        data-intercom-target="User Settings Form Area"
      >
        <Switch>
          <Route path={`/my-page/${tabRoutes.SETTINGS}`}>
            <SectionHeader title={t('MyPage.title')}>
              <Card>
                <MySettingsPage {...user} />
              </Card>
            </SectionHeader>
          </Route>
          <Route path={`/my-page/${tabRoutes.KA_REPORTS}`}>
            <MyAchievementsPage userId={user.id} />
          </Route>
          <Redirect from="/" to={`/my-page/${tabRoutes.KA_REPORTS}`} />
        </Switch>
      </Layout.Content>

      {isAllowedToGenerateData && isTestEnvironment && <DemoGenerationHelper />}
    </>
  );
};

export const GET_USER = gql`
  query getUserPage {
    me {
      name
      id
      displayName
      email
      locale
      role
    }
  }
`;
