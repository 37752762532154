import { PlusOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { Typography, Tooltip } from 'antd';
import { FC, ReactNode, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';

import { Moment } from 'moment';
import { standardDateFormat } from '../../../../../../../services/dateFormats';
import cx from 'classnames';
import './SprintWeekCard.less';
import { Sprint } from '../../../../../common/hooks/useSprintPlanningData';
import { useTranslation } from 'react-i18next';
import { DisplayDateRange } from '../../../../../../../components/DisplayDateRange';
import { MitemMoveMessage } from './sprintWeekCard/MitemMoveMessage';
import { gql, useMutation } from '@apollo/client';
import {
  Action,
  SprintWeekCard_MitemFragment,
  UpdateMitemDeadlineDocument,
} from '../../../../../../../generated/graphql';
import { showNotification } from '../../../../../../../services/fetchNotificationProperties';
import dayjs from 'dayjs';
import { Btn } from '../../../../../../../components/Button';
import { PermissionChecker } from '../../../../../../../PermissionChecker';
import { ItemTypes } from '../../MitemPlanningBoard';

interface Props {
  title: ReactNode;
  teamId: string;
  endDate: Moment;
  sprint: Sprint;
  onOpenCreateForm: () => void;
  onPendingConfirm: (mitemId: string | null) => void;
  children: ReactNode;
}

interface DraggedItem {
  mitem: SprintWeekCard_MitemFragment;
  sprint: Sprint;
}

type DropState = {
  type: 'PENDING_CONFIRM';
  mitem: SprintWeekCard_MitemFragment;
};

export const SprintWeekCard: FC<Props> = ({
  title,
  teamId,
  children,
  onOpenCreateForm,
  onPendingConfirm,
  endDate: _endDate,
  sprint,
}) => {
  const { t } = useTranslation();
  const [dropInfo, setDropInfo] = useState<DropState | null>(null);

  const [mutateDeadline] = useMutation(UpdateMitemDeadlineDocument, {
    onError: () =>
      showNotification('error', {
        message: 'Something went wrong. Failed to update deadline.',
      }),
  });

  const endDate = dayjs(standardDateFormat(_endDate)); // todo: remove after the propType is changed to Dayjs

  const updateDeadline = (
    mitem: SprintWeekCard_MitemFragment,
    newDeadline: string,
    targetSprintId?: string | null
  ) => {
    mutateDeadline({
      variables: {
        teamId: mitem.teamId,
        mitemId: mitem.id,
        deadline: {
          dueDate: newDeadline,
          mostImportantItemSprintId: targetSprintId,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateMitemDeadline: {
          ...mitem,
          deadline: newDeadline,
        },
      } as any, // todo: revisit some day and try to figure out why it dislikes `__typename: 'Mutation'`
    });
  };

  const today = dayjs();
  const isHistoric = endDate.isBefore(today, 'day');

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.MITEM,
    drop: (dragged) => {
      // when moved to a locked sprint from an unlocked
      const requiresConfirm = sprint.locked && sprint.id !== dragged.sprint.id;

      if (requiresConfirm) {
        setDropInfo({ type: 'PENDING_CONFIRM', mitem: dragged.mitem });
        onPendingConfirm(dragged.mitem.id);
      } else {
        updateDeadline(
          dragged.mitem,
          standardDateFormat(endDate),
          dragged.sprint.id
        );
      }
    },
    canDrop: (item: DraggedItem) => {
      return !endDate.isSame(item.mitem.deadline);
    },
    collect: (monitor: DropTargetMonitor<DraggedItem>) => {
      const isOverOverDifferentWeek =
        monitor?.isOver() &&
        !endDate?.isSame(monitor.getItem()?.mitem.deadline, 'day');

      return {
        isOver: isOverOverDifferentWeek,
        canDrop: isOverOverDifferentWeek,
      };
    },
  });

  const closeDropInfo = () => {
    setDropInfo(null);
    onPendingConfirm(null);
  };

  const confirmDeadlineMove = () => {
    if (dropInfo?.type !== 'PENDING_CONFIRM') return;
    updateDeadline(dropInfo.mitem, standardDateFormat(endDate), sprint?.id);
    closeDropInfo();
  };
  const overAllowedWeek = isOver && canDrop;

  const isActive = !isHistoric && sprint.locked;

  return (
    <div
      ref={drop}
      className={cx('SprintWeekCard mb', {
        'SprintWeekCard--dragOver': isOver,
        'SprintWeekCard--isHistoric': isHistoric,
        'SprintWeekCard--canDrop': overAllowedWeek,
        'SprintWeekCard--active': isActive,
      })}
    >
      <div className="mb--l">{title}</div>
      {dropInfo?.type === 'PENDING_CONFIRM' && (
        <MitemMoveMessage
          title={t('SprintWeekCard.confirmMove.title')}
          message={t('SprintWeekCard.confirmMove.text')}
          onConfirm={confirmDeadlineMove}
          onCancel={closeDropInfo}
        />
      )}
      {children}
      <PermissionChecker
        resourceOwner={{
          type: 'TeamResource',
          teamId: teamId,
          requestedAction: {
            resource: 'sprintKA',
            action: Action.CREATE,
          },
        }}
      >
        <div className="center-content mt--l">
          <Tooltip
            mouseEnterDelay={0.7}
            title={
              isHistoric
                ? t('SprintWeekCard.cantAddNew')
                : t('SprintWeekCard.addNew')
            }
          >
            <span>
              <Btn
                className="SprintWeekCard__addButton"
                disabled={isHistoric}
                type="primary"
                shape="circle"
                size="small"
                icon={
                  overAllowedWeek ? (
                    <VerticalAlignBottomOutlined />
                  ) : (
                    <PlusOutlined />
                  )
                }
                onClick={() => {
                  onOpenCreateForm();
                }}
              />
            </span>
          </Tooltip>
        </div>
      </PermissionChecker>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_WEEK_CARD_MITEM = gql`
  fragment SprintWeekCard_Mitem on Mitem {
    id
    deadline
    teamId
  }
`;

interface WeekCardTitleProps {
  startDate: Moment;
  endDate: Moment;
}
export const WeekCardTitle = ({ startDate, endDate }: WeekCardTitleProps) => {
  return (
    <div className="WeekCardTitle flx flx--ai-center">
      <div className="ml--auto" style={{ margin: -4 }}>
        <Typography.Text type="secondary">
          <DisplayDateRange startDate={startDate} endDate={endDate} />
        </Typography.Text>
      </div>
    </div>
  );
};

// this is used indirectly - `UpdateMitemDeadlineDocument` is generated from it
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const UPDATE_MITEM_DEADLINE = gql`
  mutation updateMitemDeadline(
    $teamId: ID!
    $mitemId: ID!
    $deadline: MitemDeadlineInput
  ) {
    updateMitemDeadline(
      teamId: $teamId
      mitemId: $mitemId
      deadline: $deadline
    ) {
      id
      deadline
    }
  }
`;
