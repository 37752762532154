import cx from 'classnames';
import './MilestoneIndicator.less';
import { groupBy } from '../../../../../../services/groupBy';
import { Popover } from 'antd';

interface Props {
  milestones: MilestoneWithStats[];
}

type MilestoneWithStats = {
  milestone: Milestone;
  stats: { completed: number; overdue: number; upcoming: number };
};

type Milestone = {
  id: string;
  domainId: {
    itemId: string;
    tenantId: string;
  };
  name: string;
  deadline: string;
  metadata: {
    completedAt: string | null;
    status: string;
  };
};

export const MilestoneIndicator = ({ milestones }: Props) => {
  if (milestones.length === 0) return null;

  const isLoneMileStone = milestones.length === 1;

  const byStatus = Object.entries(
    groupBy(milestones, (m) => m.milestone.metadata.status)
  ).filter(([_status, milestones]) => milestones.length > 0);

  return (
    <div className="MilestoneIndicator">
      {byStatus.map(([status, milestones]) => (
        <div
          key={status}
          className={cx('MilestoneIndicator__status', {
            'MilestoneIndicator__status--isLoneMileStone': isLoneMileStone,
            [`MilestoneIndicator__status--${status}`]: true,
          })}
        >
          <Popover
            content={milestones.map((m) => (
              <div key={m.milestone.id}>{m.milestone.name}</div>
            ))}
            overlayStyle={{
              paddingLeft: 30,
              paddingRight: 30,
              maxWidth: 1200,
            }}
          >
            <div className="MilestoneIndicator__count">{milestones.length}</div>
          </Popover>
        </div>
      ))}
    </div>
  );
};
