import dayjs from 'dayjs';

interface Sprint {
  endDate?: string;
  id?: string | null;
  finalized?: boolean;
}

export const checkIfPlannedSprint = (sprint: Sprint | null) => {
  const isPlannedSprint =
    sprint?.id == null &&
    sprint?.endDate &&
    dayjs(sprint.endDate).isAfter(dayjs(), 'day');

  return Boolean(isPlannedSprint);
};

export const checkIfActiveSprint = (sprint: Sprint | null) => {
  const isActiveSprint =
    sprint?.id != null &&
    dayjs(sprint.endDate).isAfter(dayjs(), 'day') &&
    sprint.finalized === false;

  return Boolean(isActiveSprint);
};
