import { gql } from '@apollo/client';
import { isDefined } from '../../../../../services/isDefined';
import { MigFilter } from '../../common/components/mitemFilters/MigFilter';
import { OwnerFilter } from '../../common/components/mitemFilters/OwnerFilter';
import { StatusFilter } from '../../common/components/mitemFilters/StatusFilter';
import { TagFilter } from '../../common/components/mitemFilters/TagFilter';
import { UntaggedOnlyFilter } from '../../common/components/UntaggedOnlyFilter';
import { MitemFilters } from '../../common/hooks/useMitemFilters';
import { PlanViewFilterHeader_MitemFragment } from '../../../../../generated/graphql';

interface Props {
  mitems?: PlanViewFilterHeader_MitemFragment[];
  currentFilters: MitemFilters['currentFilters'];
  setFilter: MitemFilters['setFilter'];
  teamId: string;
}

export const PlanViewFilterHeader = ({
  mitems,
  currentFilters,
  setFilter,
  teamId,
}: Props) => {
  return (
    <div
      className="flx flx--ai-center"
      data-intercom-target="Sprint View Table Filter Area"
    >
      <MigFilter
        migs={mitems?.map((m) => m.supportedMigs?.[0]).filter(isDefined) ?? []}
        value={currentFilters.migId}
        onChange={setFilter.migId}
      />

      <StatusFilter
        value={currentFilters.status}
        onChange={setFilter.mitemStatus}
      />

      <OwnerFilter
        owners={mitems?.map((m) => m?.owner).filter(isDefined) ?? []}
        value={currentFilters.ownerId}
        onChange={setFilter.mitemOwnerId}
      />
      <TagFilter teamId={teamId} />
      <UntaggedOnlyFilter />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PLAN_VIEW_FILTER_HEADER = gql`
  fragment PlanViewFilterHeader_Mitem on Mitem {
    id
    owner {
      id
      name
      email
      displayName
    }
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
  }
`;
