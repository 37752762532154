import {
  EditOutlined,
  MoreOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import { MenuProps, Dropdown } from 'antd';
import { Btn } from '../Button';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '../../icons/overviewIcons/CheckIcon';
import { useState } from 'react';
import { EditInitiativeModal } from '../../appPages/company/initiatives/initiativeDetails/titleSection/components/editInitiativeModal/EditInitiativeModal';
import { CreateSubInitiativeModal } from '../../appPages/company/initiatives/initiativeDetails/titleSection/components/createSubInitiativeModal/CreateSubInitiativeModal';
import { useParams } from 'react-router-dom';
import { GroupsIcon } from '../../icons/GroupsIcon';
import { AccountTreeOutlineIcon } from '../../icons/initiativeIcons/AccountTreeOutlineIcon';
import { ArchiveIcon } from '../../icons/initiativeIcons/ArchiveIcon';

const nope = (
  <span className="ml font-size--sm txt--danger">(Not yet implemented)</span>
);

export const InitiativeActionsDropdown = () => {
  const { t } = useTranslation();
  const { initiativeId } = useParams<{ initiativeId: string }>();

  const [showModal, setShowModal] = useState<
    'createSubModal' | 'editModal' | 'archiveModal' | null
  >(null);

  const initiativeActions: MenuProps['items'] = [
    {
      key: 'Edit',
      label: t('InitiativeActionsDropdown.edit'),
      icon: <EditOutlined />,
      onClick: () => {
        setShowModal('editModal');
      },
    },
    {
      key: 'Create subinitiative',
      label: t('InitiativeActionsDropdown.createSubinitiative'),
      icon: <QuestionOutlined />,
      onClick: () => {
        setShowModal('createSubModal');
      },
    },
    {
      key: 'Manage teams',
      label: (
        <span>
          {t('InitiativeActionsDropdown.manageTeams')} {nope}
        </span>
      ),
      icon: <GroupsIcon />,
    },
    {
      key: 'Realign',
      label: (
        <span>
          {t('InitiativeActionsDropdown.realign')} {nope}
        </span>
      ),
      icon: <AccountTreeOutlineIcon />,
    },
    {
      key: 'Mark as Completed',
      label: (
        <span>
          {t('InitiativeActionsDropdown.markAsCompleted')} {nope}
        </span>
      ),
      icon: <CheckIcon />,
    },
    {
      key: 'Archive',
      label: (
        <span>
          {t('common.archive')} {nope}
        </span>
      ),
      icon: <ArchiveIcon />,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items: initiativeActions }}>
        <Btn icon={<MoreOutlined />} />
      </Dropdown>
      <EditInitiativeModal
        initiativeId={initiativeId}
        visible={showModal === 'editModal'}
        onClose={() => setShowModal(null)}
      />

      <CreateSubInitiativeModal
        initiativeId={initiativeId}
        visible={showModal === 'createSubModal'}
        onClose={() => setShowModal(null)}
      />
    </>
  );
};
