import { Drawer, Form, Skeleton } from 'antd';
import { DrawerTitle } from '../../../../components/DrawerTitle';
import { InitiativeForm } from './InitiativeForm';
import { showNotification } from '../../../../services/fetchNotificationProperties';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useEditInitiative } from './hooks/useEditInitiative';
import { howweErrorParser } from '../../../../services/howweErrorParser';
import { useMe } from '../../../../hooks/useMe';
import { Btn } from '../../../../components/Button';
import { useInitiativeLazy } from '../../../../hooks/useInitiative';

interface Props {
  visible: boolean;
  onClose: () => void;
  onCompleted?: () => void;
  initiativeDomainId: { teamId: string; itemId: string };
}

export const EditInitiativesDrawer = ({
  visible,
  onClose,
  onCompleted,
  initiativeDomainId,
}: Props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { isAdmin } = useMe();
  const [fetchTeamInitiative, { data, loading }] = useInitiativeLazy();

  const includeTagEditing = isAdmin ?? false;

  useEffect(() => {
    if (visible && initiativeDomainId) {
      fetchTeamInitiative({
        variables: {
          teamId: initiativeDomainId.teamId,
          initiativeId: initiativeDomainId.itemId,
        },
      });
    }
  }, [initiativeDomainId, visible, fetchTeamInitiative]);

  const { editInitiative } = useEditInitiative();

  let howweErrors;

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const { description, name, iconId, colorCode, tagTitle } = values;
      const migsIds = values.migs as string[];
      const objectives: string[] = values.objectives ?? [];
      let tag;

      if (isAdmin) {
        tag = {
          title: tagTitle,
          iconId: iconId,
          colorCode: colorCode,
        };
      } else {
        tag = {
          title: tagTitle,
        };
      }

      editInitiative({
        variables: {
          teamId: initiativeDomainId.teamId,
          initiativeId: initiativeDomainId.itemId,
          initiative: {
            description: description,
            supportsInitiatives: [],
            supportsMigs: migsIds.map((migId) => ({ id: migId })),
            objectives: objectives
              .filter((o) => !!o)
              .map((objective: string) => ({
                text: objective,
              })),
            name: name,
            tag: tag,
            rev: data?.initiative.rev ?? '',
          },
        },
        onCompleted: (data) => {
          showNotification('success', {
            message: (
              <strong>
                {t('common.initiative.editSuccess', {
                  name: data.editInitiative.name,
                })}
              </strong>
            ),
          });
          onCompleted?.();
        },
        onError: (e) => {
          howweErrors = howweErrorParser(e);

          showNotification('error', {
            message: (
              <strong>
                <ul>
                  {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
                </ul>
              </strong>
            ),
          });
        },
      });
    });
  };

  return (
    <div>
      <Drawer
        placement="right"
        closable={true}
        width={400}
        open={visible}
        onClose={onClose}
        title={
          <DrawerTitle>
            {t('EditInitiativesDrawer.editInitiativeHeader')}
          </DrawerTitle>
        }
      >
        {data?.initiative ? (
          <InitiativeForm
            form={form}
            initialValue={data?.initiative}
            loading={loading}
            includeTagEditing={includeTagEditing}
            isEdit={true}
          />
        ) : (
          <Skeleton />
        )}

        <Btn
          type="primary"
          htmlType="submit"
          onClick={handleSubmit}
          loading={loading}
        >
          {t('common.save')}
        </Btn>
      </Drawer>
    </div>
  );
};
