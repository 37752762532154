import { PlusOutlined } from '@ant-design/icons';
import { Divider, Radio, Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTags } from '../../../../../../hooks/useGetTags';
import { useTeamInitiativesLazy } from '../../../../../../hooks/useTeamInitiatives';
import { useTenantDetails } from '../../../../../../hooks/useTenantDetails';
import { InitiativeTag } from '../../../../../../components/initiative/InitiativeTag';
import { Icons } from '../../../../../company/initiatives_old/initiativesPageV1/InitiativeIcons';
import { modalType } from '../../../../setup/overviewPage/labels/AddLabelModal';
import { AddOrUpdateTagModal } from '../../../../setup/overviewPage/labels/AddOrUpdateTagModal';
import { TagImage } from '../../../../setup/overviewPage/labels/TagImage';
import { SorterConjunction, tagsContext } from '../../TeamSprintProvider';
import { Btn } from '../../../../../../components/Button';
import { PermissionChecker } from '../../../../../../PermissionChecker';
import { stringSort } from '../../../../../../services/stringSort';
import { Action } from '../../../../../../generated/graphql';

interface Props {
  teamId: string;
}

export const TagFilter = ({ teamId }: Props) => {
  const [createTagModalOpen, setCreateTagModalOpen] = useState(false);
  const { Option, OptGroup } = Select;
  const { tags } = useGetTags(teamId);
  const {
    features: { hasInitiative },
  } = useTenantDetails();
  const [fetchTeamInitiatives, { teamInitiatives }] = useTeamInitiativesLazy();
  const {
    setTagsForFiltering,
    untaggedOnly,
    tagsSelectedForFiltering,
    andOrChoice: value,
    setAndOrChoiceForFiltering,
  } = useContext(tagsContext) as any;
  const { t } = useTranslation();
  const [newTag, setNewTag] = useState<{
    name: string;
    id: string;
    backgroundColor: string;
  }>();

  useEffect(() => {
    if (hasInitiative && teamId) {
      fetchTeamInitiatives({ variables: { teamId } });
    }
  }, [teamId, hasInitiative, fetchTeamInitiatives]);

  const inputRef = useRef<BaseSelectRef>(null);

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [tagNameInSearchBar, setTagNameInSearchBar] = useState<
    string | undefined
  >();

  const activeInitiatives = useMemo(() => {
    return teamInitiatives
      ?.filter((ti) => !ti.archived)
      .toSorted((a, b) => stringSort(a.tag.title, b.tag.title));
  }, [teamInitiatives]);

  const showOptionGroups =
    activeInitiatives && activeInitiatives.length > 0 && hasInitiative;

  return (
    <>
      <div
        className="MitemFilters__filterContainer mr--s"
        data-intercom-target="Sprint View Table Owner Filter"
      >
        <div className="flx">
          <h5>{t('TagFilter.title')}&nbsp;</h5>
        </div>

        <Select
          popupMatchSelectWidth={false}
          disabled={untaggedOnly}
          placeholder={t('TagFilter.addTag')}
          ref={inputRef}
          open={dropdownOpen}
          onFocus={() => {
            setDropdownOpen(true);
          }}
          onBlur={() => {
            setNewTag(undefined);
            setDropdownOpen(false);
          }}
          mode="multiple"
          defaultValue={[]}
          onChange={setTagsForFiltering}
          value={tagsSelectedForFiltering ?? []}
          className="MitemFilter__Select MitemFilter__TagFilter"
          showSearch
          filterOption={(input, option) => {
            setTagNameInSearchBar(input);
            if (!option) return false;
            if ('options' in option) return false;
            return (option.label as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
          dropdownRender={(menu) => (
            <>
              <div
                // These preventDefaults are needed to avoid dropdown closing on every click in header or footer
                // - that's the only working solution I came up with
                onMouseDown={(e) => {
                  setDropdownOpen(true);
                  e.preventDefault();
                  return false;
                }}
                onClick={(e) => {
                  e.preventDefault();
                  return false;
                }}
              >
                <div
                  className="flx flx--jc-center"
                  hidden={
                    !tagsSelectedForFiltering ||
                    tagsSelectedForFiltering?.length < 2
                  }
                >
                  <div className="tag-filter--radio flx">
                    <div
                      onClick={() =>
                        setAndOrChoiceForFiltering(SorterConjunction.AND)
                      }
                    >
                      <Radio checked={value === SorterConjunction.AND}>
                        <span className="capitalize">{t('TagFilter.and')}</span>
                      </Radio>
                    </div>
                    <div
                      onClick={() =>
                        setAndOrChoiceForFiltering(SorterConjunction.OR)
                      }
                    >
                      <Radio checked={value === SorterConjunction.OR}>
                        <span className="capitalize">{t('TagFilter.or')}</span>
                      </Radio>
                    </div>
                  </div>
                </div>
              </div>
              <Divider
                className={`mb--s mt--xs ${
                  !tagsSelectedForFiltering ||
                  tagsSelectedForFiltering?.length < 2
                    ? 'd-none'
                    : ''
                }`}
              />
              {menu}

              <PermissionChecker
                resourceOwner={{
                  type: 'TeamResource',
                  teamId: teamId,
                  requestedAction: [
                    {
                      resource: 'teamTag',
                      action: Action.CREATE,
                    },
                  ],
                }}
              >
                <Divider className="mb--xs mt--xs" />
                <div
                  className="flx flx--jc-center mt mb--s"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                >
                  <Btn
                    type="link"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setCreateTagModalOpen(!createTagModalOpen);
                    }}
                  >
                    {t('TagFilter.createTag')}
                  </Btn>
                </div>
              </PermissionChecker>
            </>
          )}
        >
          {/* This below moves recently added tag to the top of the select dropdown */}

          {showOptionGroups ? (
            <>
              <OptGroup label={t('TagFilter.initiatives')}>
                {activeInitiatives.map((initiative) => (
                  <Option
                    key={initiative.id}
                    value={initiative.id}
                    label={initiative.tag.title}
                  >
                    <InitiativeTag
                      title={initiative.tag.title}
                      borderColor={initiative.tag.colorCode}
                      icon={Icons[initiative.tag.iconId as keyof typeof Icons]}
                      completed={initiative.completed?.value}
                      archived={initiative.archived}
                    />
                  </Option>
                ))}
              </OptGroup>

              <OptGroup label={t('TagFilter.teamTag')}>
                {[
                  ...tags.filter((t) => t.id === newTag?.id),
                  ...tags.filter((t) => t.id !== newTag?.id),
                ].map((tag) => (
                  <Option key={tag.id} value={tag.id} label={tag.name}>
                    <TagImage tag={tag} highlight={tag.id === newTag?.id} />
                  </Option>
                ))}
              </OptGroup>
            </>
          ) : (
            <>
              {[
                ...tags.filter((t) => t.id === newTag?.id),
                ...tags.filter((t) => t.id !== newTag?.id),
              ].map((tag) => (
                <Option key={tag.id} value={tag.id} label={tag.name}>
                  <TagImage tag={tag} highlight={tag.id === newTag?.id} />
                </Option>
              ))}
            </>
          )}
        </Select>
        <AddOrUpdateTagModal
          teamId={teamId}
          visible={createTagModalOpen}
          onCancel={() => {
            inputRef.current?.focus();
            setCreateTagModalOpen(false);
          }}
          onCompleted={(data) => {
            setNewTag(data);
            inputRef.current?.focus();
            setCreateTagModalOpen(false);
          }}
          title={t('TagFilter.createTag')}
          modalActionType={modalType.createTag}
          currentName={tagNameInSearchBar}
        />
      </div>
    </>
  );
};
