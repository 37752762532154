import { gql } from '@apollo/client';
import {
  CompanyInitiativeTable__TopInitiativeReportFragment,
  DurationStatus,
} from '../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import Table, { ColumnProps } from 'antd/es/table';
import { match } from 'ts-pattern';
import { InitiativeTag } from '../../../../initiative/InitiativeTag';
import { stringSort } from '../../../../../services/stringSort';
import { RemoveInitiativeLinkToTenant } from './RemoveInitiativeLinkToTenant';
import { Colors } from '../../../../../appPages/componentLibrary/Colors';
import { CompanyInitiativeTableSkeleton } from './CompanyInitiativeTable.skeleton';

interface Props {
  initiatives: CompanyInitiativeTable__TopInitiativeReportFragment[];
}

export const CompanyInitiativeTable = ({ initiatives }: Props) => {
  const { t } = useTranslation();
  const initiativeColumns: ColumnProps<CompanyInitiativeTable__TopInitiativeReportFragment>[] =
    [
      {
        title: t('common.status'),
        width: 120,
        key: 'status',
        dataIndex: ['initiative', 'metadata'],
        sorter: (a, b) =>
          stringSort(
            a.initiative.metadata.status,
            b.initiative.metadata.status
          ),
        render: (metadata) => {
          const statusToRender = match(metadata)
            .with({ status: DurationStatus.COMPLETED }, () => (
              <div className="bold" style={{ color: Colors.Status.OK_GREEN }}>
                {t('common.initiative.status.completed')}
              </div>
            ))
            .with({ status: DurationStatus.UPCOMING }, () => (
              <div
                className="bold"
                style={{ color: Colors.Status.FUTURE_PURPLE }}
              >
                {t('common.initiative.status.upcoming')}
              </div>
            ))
            .with({ status: DurationStatus.ACTIVE }, () => (
              <div className="bold" style={{ color: '#79bcff' }}>
                {t('common.initiative.status.active')}
              </div>
            ))
            .otherwise(() => '');

          return <div className="text-c">{statusToRender}</div>;
        },
      },
      {
        title: t('CompanyInitiativeTable.tagColumn'),
        width: 120,
        key: 'status',
        dataIndex: ['initiative', 'tag'],
        render: () => {
          return (
            <InitiativeTag
              tag={{ title: 'test', colorCode: 'pink', iconId: 'carIcon' }}
              hideTitle
            />
          );
        },
      },
      {
        title: t('CompanyInitiativeTable.initiativeColumn'),
        key: 'name',
        sorter: (a, b) => stringSort(a.initiative.name, b.initiative.name),
        dataIndex: ['initiative', 'name'],
        render: (text) => {
          return (
            <div>
              <div>{text}</div>
            </div>
          );
        },
      },
      {
        title: '',
        key: 'unlink',
        width: 90,
        render: (_, initiative) => (
          <RemoveInitiativeLinkToTenant
            initiativeDomainId={initiative.domainId}
          />
        ),
      },
    ];

  return (
    <Table
      columns={initiativeColumns}
      dataSource={initiatives}
      style={{ borderRadius: '6px' }}
      pagination={false}
      rowKey={(initiative) => initiative.id}
    />
  );
};

CompanyInitiativeTable.Skeleton = CompanyInitiativeTableSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const COMPANY_INITIATIVE_TABLE__TOP_INITIATIVE_REPORT = gql`
  fragment companyInitiativeTable__TopInitiativeReport on TopInitiativeReport {
    id
    domainId {
      itemId
      tenantId
    }
    initiative {
      domainId {
        itemId
        tenantId
      }
      name
      id
      tag {
        title
        iconId
        colorCode
      }
      metadata {
        completedAt
        archived
        status
      }
    }
    accumulatedStats {
      milestones {
        completed
        overdue
        upcoming
      }
    }
  }
`;
