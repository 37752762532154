import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetTeamWeeklyKeyActivitiesDocument,
  PublishStatus,
} from '../../../../../../../generated/graphql';
import { weeklyKeyActivityDTOtoAkpiData } from '../../../../../../../services/akpiUtils';
import { WkaCollapsibleCard } from './components/WkaCollapsibleCard';
import { ToggleSection } from '../../../ToggleSection';
import { groupByStatus } from '../../../../../common/utils/teamPageHelpers';
import { stringSort } from '../../../../../../../services/stringSort';
import { TeamEntityPlaceholder } from '../../../TeamEntityPlaceholder';
import { useCurrentLocale } from '../../../../../../../hooks/useCurrentLocale';
import { TeamOverviewPageDivider } from '../../../TeamOverviewPageDivider';

interface Props {
  teamId: string;
}

export const WkaSection = ({ teamId }: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const { locale } = useCurrentLocale();
  const { data } = useSuspenseQuery(GetTeamWeeklyKeyActivitiesDocument, {
    variables: { teamId },
  });

  const selectedLanguage = locale;

  const weeklyKeyActivities = data?.weeklyKeyActivities.map(
    weeklyKeyActivityDTOtoAkpiData
  );

  const wkaByStatus = groupByStatus(
    weeklyKeyActivities ?? [],
    Object.values(PublishStatus)
  );

  const { ENDED, UPCOMING, ACTIVE } = wkaByStatus;

  const showEmptyComponent = UPCOMING.length === 0 && ENDED.length === 0;
  const showEmptyComponentActiveWkas = ACTIVE.length === 0;

  return (
    <div>
      <h2 className="mb--l" data-intercom-target="WKA section header">
        {t('WkaSection.header')}
      </h2>
      {showEmptyComponentActiveWkas && (
        <div className="flx flx--jc-center">
          <TeamEntityPlaceholder
            header={t('WkaSection.noWkaPlaceholder.header')}
            text={t('WkaSection.noWkaPlaceholder.text')}
            buttonText={t('WkaSection.noWkaPlaceholder.buttonText')}
            url={`https://help.howwe.io/${selectedLanguage}/articles/108559-weekly-key-activities`}
            size="small"
            data-intercom-target="No active WKAs placeholder"
          />
        </div>
      )}
      {ACTIVE.sort((a, b) => stringSort(a.name, b.name)).map((wka) => {
        return (
          <WkaCollapsibleCard
            key={wka.id}
            wka={wka}
            onToggleExpand={() =>
              setExpanded((prev) => ({
                ...prev,
                [wka.id]: !prev[wka.id],
              }))
            }
            isExpanded={expanded[wka.id]}
          />
        );
      })}

      <div className="flx flx--jc-center flx--column">
        <ToggleSection
          titleExpanded={t('WkaSection.toggleSectionExpanded')}
          titleNotExpanded={t('WkaSection.toggleSectionNotExpanded')}
          data-intercom-target="Expandable section to show ended and upcoming WKAs"
        >
          <div className="mt--l">
            {showEmptyComponent && (
              <div className="flx flx--jc-center">
                <TeamEntityPlaceholder
                  header={t('WkaSection.noWkaPlaceholder.headerToggleSection')}
                  text={t('WkaSection.noWkaPlaceholder.text')}
                  buttonText={t('WkaSection.noWkaPlaceholder.buttonText')}
                  url={`https://help.howwe.io/${selectedLanguage}/articles/108559-weekly-key-activities`}
                  data-intercom-target="No ended or upcoming WKAs placeholder"
                />
              </div>
            )}
            {UPCOMING.length > 0 && (
              <TeamOverviewPageDivider title={t('common.upcoming')} />
            )}
            {UPCOMING.sort((a, b) => {
              const dateSort = stringSort(a.startDate, b.startDate);
              if (dateSort === 0) {
                return stringSort(a.name, b.name);
              }
              return dateSort;
            }).map((wka) => {
              return (
                <WkaCollapsibleCard
                  key={wka.id}
                  wka={wka}
                  onToggleExpand={() =>
                    setExpanded((prev) => ({
                      ...prev,
                      [wka.id]: !prev[wka.id],
                    }))
                  }
                  isExpanded={expanded[wka.id]}
                />
              );
            })}
            {ENDED.length > 0 && (
              <TeamOverviewPageDivider title={t('common.ended')} />
            )}
            {ENDED.sort((a, b) => {
              const dateSort = stringSort(a.endDate, b.endDate, 'desc');
              if (dateSort === 0) {
                return stringSort(a.name, b.name);
              }
              return dateSort;
            }).map((wka) => {
              return (
                <WkaCollapsibleCard
                  key={wka.id}
                  wka={wka}
                  onToggleExpand={() =>
                    setExpanded((prev) => ({
                      ...prev,
                      [wka.id]: !prev[wka.id],
                    }))
                  }
                  isExpanded={expanded[wka.id]}
                />
              );
            })}
          </div>
        </ToggleSection>
      </div>
    </div>
  );
};

export const GET_TEAM_WEEKLY_KEY_ACTIVITIES = gql`
  query getTeamWeeklyKeyActivities($teamId: ID!, $statusFilter: PublishStatus) {
    weeklyKeyActivities(teamId: $teamId, statusFilter: $statusFilter) {
      id
      teamId
      name
      status
      ...WkaCollapsibleCard_WeeklyKeyActivity
    }
  }
`;
