import { gql } from '@apollo/client';

// todo: this can probably be trimmed to only include attributes shown in the list
/* @deprecated do not use */
export const MitemFragment = gql`
  fragment BaseMitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportsInitiatives {
      id
      domainId {
        tenantId
        teamId
        itemId
      }
    }
    supportedInitiatives {
      id
      archived
      completed {
        value
      }
      tag {
        title
        iconId
        colorCode
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner {
      id
      email
      name
      displayName
      archivedAt
    }
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
    teamId
  }
`;
