import { useTranslation } from 'react-i18next';

import { ToggleSection } from '../ToggleSection';

import { gql } from '@apollo/client';
import { MigCollapsibleCardWithActionsSkeleton } from './components/MigCollapsibleCardWithActions.skeleton';

export const MigSectionSkeleton = () => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <h2 className="mb--l" data-intercom-target="MIG section header">
          {t('MigSection.header')}
        </h2>
        <MigCollapsibleCardWithActionsSkeleton />
        <MigCollapsibleCardWithActionsSkeleton />

        <div className="flx flx--jc-center flx--column">
          <ToggleSection
            titleExpanded={t('MigSection.toggleSectionExpanded')}
            titleNotExpanded={t('MigSection.toggleSectionNotExpanded')}
            data-intercom-target="Expandable section to show ended and upcoming MIGs"
            disabled={true}
          >
            <div className="mt--l"></div>
          </ToggleSection>
        </div>
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_SECTION_MIG_FRAGMENT = gql`
  fragment MigSection_MigV2 on MigV2 {
    id
    startDate
    ...MigCollapsibleCardWithActions_MigV2
  }
`;
