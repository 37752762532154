import { useSpring, animated } from 'react-spring';

interface Props {
  totalCommitted: number;
  recommendedCommitment: number;
}

export const CommitmentQuota = ({
  totalCommitted,
  recommendedCommitment,
}: Props) => {
  const commitment = useSpring<{
    totalCommitted: number;
    recommendedCommitment: number;
  }>({
    totalCommitted,
    recommendedCommitment,
    config: { duration: 300 },
  });

  const totalCommittedDecimals = getDecimals(totalCommitted);
  const recommendedCommitmentDecimals = getDecimals(recommendedCommitment);

  const format = (x: number, decimals: string) => {
    const integerPart = Math.floor(x);
    return decimals ? `${integerPart}.${decimals}` : integerPart;
  };
  return (
    <>
      <animated.span data-testid="commitmentQuotaTotalCommitment">
        {commitment.totalCommitted.interpolate((x) =>
          format(x, totalCommittedDecimals)
        )}
      </animated.span>
      /
      <animated.span>
        {commitment.recommendedCommitment.interpolate((x) =>
          format(x, recommendedCommitmentDecimals)
        )}
      </animated.span>
    </>
  );
};

const getDecimals = (number: number) =>
  parseFloat(number.toFixed(2)).toString().split('.')[1];
