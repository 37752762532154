import { Form, Modal } from 'antd';
import { Suspense } from 'react';
import { EditInitiativeModalContent } from './EditInitiativeModalContent';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../../../../components/ErrorPage';

interface Props {
  initiativeId: string;
  visible: boolean;
  onClose?: () => void;
}

export const EditInitiativeModal = ({
  visible,
  initiativeId,
  onClose,
}: Props) => {
  const [form] = Form.useForm();

  return (
    <Modal
      open={visible}
      width={766}
      footer={null}
      style={{ top: 20 }}
      onCancel={() => {
        onClose?.();
        form.resetFields();
      }}
      destroyOnClose
    >
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense fallback={<EditInitiativeModalContent.Skeleton />}>
          <EditInitiativeModalContent form={form} initiativeId={initiativeId} />
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};
