import { Colors } from '../../appPages/componentLibrary/Colors';
import { Icons } from '../../appPages/company/initiatives_old/initiativesPageV1/InitiativeIcons';
import './InitiativeTag.less';
import { CheckIcon } from '../../icons/overviewIcons/CheckIcon';
import { match } from 'ts-pattern';
import { ArchivedIcon } from '../../icons/overviewIcons/ArchivedIcon';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

interface PropsAlt1 {
  title: string;
  borderColor: string;
  icon: React.ReactNode;
  hideTitle?: boolean;
  completed?: boolean;
  archived?: boolean;
  className?: string;
}

interface PropsAlt2 {
  tag: { iconId: string; title: string; colorCode: string };
  hideTitle?: boolean;
  completed?: boolean;
  archived?: boolean;
  className?: string;
}

type Props = PropsAlt1 | PropsAlt2;

export const InitiativeTag = (props: Props) => {
  const { t } = useTranslation();
  let icon, title, borderColor;

  if ('tag' in props) {
    icon = Icons[props.tag.iconId as keyof typeof Icons];
    title = props.tag.title;
    borderColor = props.tag.colorCode;
  } else {
    icon = props.icon;
    title = props.title;
    borderColor = props.borderColor ?? Colors.Grays.WHITE;
  }

  const infoIcon = match(props)
    .with({ archived: true }, () => (
      <Tooltip placement="topRight" title={t('InitiativeTag.archived')}>
        <div className="InitiativeTag__icon InitiativeTag__icon--archived">
          <ArchivedIcon />
        </div>
      </Tooltip>
    ))
    .with({ completed: true }, () => (
      <Tooltip placement="topRight" title={t('InitiativeTag.completed')}>
        <div className="InitiativeTag__icon InitiativeTag__icon--completed">
          <CheckIcon style={{ color: Colors.Status.OK_GREEN }} />
        </div>
      </Tooltip>
    ))
    .otherwise(() => null);

  return (
    <div
      className={cx('InitiativeTag', props.className)}
      style={{ borderColor: borderColor }}
    >
      {infoIcon}
      <div className="flx InitiativeTag__content">
        <div
          className={!props.hideTitle ? 'mr--s' : ''}
          style={{ fontSize: 12 }}
        >
          {icon}
        </div>
        {!props.hideTitle && title}
      </div>
    </div>
  );
};
