import { gql, PureQueryOptions, useMutation } from '@apollo/client';
import { Divider, Drawer, Form, Typography } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerTitle } from '../../../../../components/DrawerTitle';
import { ErrorAlert } from '../../../../../components/ErrorAlert';
import { LearnMoreLink } from '../../../../../components/LearnMoreLink';
import { SKADrawerConfirmCloseAlert } from '../../../../../components/SKADrawerConfirmCloseAlert';
import {
  CreateMitemDrawer_MitemFragment,
  CreateSkaDocument,
  SprintKaInput,
} from '../../../../../generated/graphql';
import { useAccelerationDay } from '../../../../../hooks/useAccelerationDay';
import { useSprintTerms } from '../../../../../hooks/useSprintTerms';
import { standardDateFormat } from '../../../../../services/dateFormats';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { useSprintPlanningData } from '../../../common/hooks/useSprintPlanningData';
import { MitemFragment } from '../../common/components/mitemFragment';
import './CreateMitemDrawer.less';
import { getSprintDateStatus } from './getSprintDateStatus';
import { SprintKaForm } from './SprintKaForm';
import { howweErrorParser } from '../../../../../services/howweErrorParser';
import { MIG_ASSOCIATION_OTHER } from '../SprintPlanningPage';

interface Props {
  teamId: string;
  showModal: boolean;
  onCompleted?: (data: CreateMitemDrawer_MitemFragment) => void;
  onCancel: () => void;
  showHelpLinks?: boolean;
  prefilledDeadline?: string | null;
  refetchQueries?: Array<string | PureQueryOptions>;
}

export const CreateMitemDrawer = ({
  teamId,
  showModal,
  onCompleted,
  onCancel,
  prefilledDeadline,
  refetchQueries,
  showHelpLinks = true,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const handleCancel = () => {
    if (formIsDirty) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      onCancel();
      form.resetFields();
    }
  };
  const { data: sprintData, error } = useSprintPlanningData(teamId);
  const { data: termsData } = useSprintTerms(teamId);
  const terms = termsData ?? [];

  const resetConfirmationData = () => {
    setFormIsDirty(false);
    setShowWarning(false);
    setConfirmed(false);
    form.resetFields();
  };

  const { data: accelerationDay } = useAccelerationDay(teamId);
  useEffect(() => {
    if (confirmed) {
      resetConfirmationData();
      onCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed]);

  useEffect(() => {
    // We explicitly set the deadline field here when prefilledDeadline changes instead of utilizeing initialValues
    // this is because `formRef.resetFields()` will use the values initialValues that were set before the call
    // which causes the deadline field to be incorrectly filled with an old deadline in some cases.
    form.setFieldsValue({
      deadline: prefilledDeadline && dayjs(prefilledDeadline),
    });
  }, [form, prefilledDeadline]);

  // TODO: Should be changed, need to handle intercom on a higher level
  const isAccMeeting = window.location.href.includes('acceleration-meeting');

  useEffect(() => {
    if (!isAccMeeting) {
      (window as any).Intercom?.('update', {
        hide_default_launcher: showModal,
      });

      // sometimes the drawer is unmounted rather than getting the prop showModal={false}
      // this will make sure we show the intercom widget again in that case
      return () =>
        (window as any).Intercom?.('update', {
          hide_default_launcher: false,
        });
    }

    if (!showModal) {
      setFormIsDirty(false);
      setShowWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, isAccMeeting]);

  const [createSKA, { loading: createPending }] = useMutation(
    CreateSkaDocument,
    {
      refetchQueries: refetchQueries,
      onCompleted: (data) => {
        onCompleted?.(data.createSprintKeyActivity);
        showNotification('success', {
          message: t('CreateMitemDrawer.saveSuccess'),
          description: data.createSprintKeyActivity.name,
          placement: 'top',
        });
        const lastUsedDate = form.getFieldValue('deadline');
        resetConfirmationData();
        form.setFieldsValue({ deadline: lastUsedDate });
      },
      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: t('CreateMitemDrawer.saveError'),
          description: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
      update(cache, { data }) {
        if (data?.createSprintKeyActivity) {
          cache.modify({
            fields: {
              sprintKeyActivities(existingMItems = []) {
                const newMItemRef = cache.writeFragment({
                  data: data.createSprintKeyActivity,
                  fragment: MitemFragment,
                });
                return {
                  sprintKeyActivities: [
                    ...existingMItems.sprintKeyActivities,
                    newMItemRef,
                  ],
                };
              },
            },
          });
        }
      },
    }
  );

  const handleCreateMitem = (mitem: any) => {
    let tags = mitem.tags;
    let noMigAssociation: string | null | undefined = mitem.noMigAssociation;
    const supportsInitiativeIds =
      (mitem.supportsInitiativeIds as (string | null | undefined)[]) ?? [];

    const { supportsMigId, ...rest } = mitem;

    const noMigSelected =
      supportsMigId === MIG_ASSOCIATION_OTHER || supportsMigId == null;

    const mitemToSubmit: SprintKaInput = {
      ...rest,
      supportsInitiativeIds: supportsInitiativeIds.filter((i) => i),
      tags,
      supportsMigIds: noMigSelected ? [] : [{ id: supportsMigId }],
      noMigAssociation: noMigSelected ? noMigAssociation : null,
      deadline: standardDateFormat(mitem.deadline),
    };

    createSKA({ variables: { teamId, sprintKeyActivity: mitemToSubmit } });
  };
  const currentSprint =
    sprintData?.currentSprintAndOnward && sprintData?.currentSprintAndOnward[0];
  const loading = !currentSprint || !accelerationDay;

  const status =
    prefilledDeadline && currentSprint && accelerationDay
      ? getSprintDateStatus({
          selectedDate: moment(prefilledDeadline),
          accelerationDay,
          currentSprint,
          terms,
        })
      : null;

  return (
    <Drawer
      title={
        <DrawerTitle className="CreateMitemDrawer__title">
          {t('CreateMitemDrawer.modalTitle')}
        </DrawerTitle>
      }
      open={showModal}
      onClose={handleCancel}
      width={420}
      destroyOnClose
      forceRender={true}
      styles={{ header: { backgroundColor: '#eaeaea' } }}
      drawerStyle={{
        borderLeft: '8px solid #eaeaea',
      }}
    >
      {showWarning && (
        <SKADrawerConfirmCloseAlert
          onConfirm={() => setConfirmed(true)}
          onClose={() => setShowWarning(false)}
        />
      )}
      {!loading && (
        <SprintKaForm
          teamId={teamId}
          isEdit={false}
          mitemStatus={status}
          submitPending={createPending}
          onSubmit={handleCreateMitem}
          onCancel={handleCancel}
          setIsFormDirty={setFormIsDirty}
          formRef={form}
        />
      )}

      {showHelpLinks && (
        <>
          <Divider />
          <Typography.Title level={4} className="pb">
            {t('CreateMitemDrawer.helpLinks.learnMore')}
          </Typography.Title>
          <div className="pb">
            <LearnMoreLink
              urlTemplate="https://help.howwe.io/{{locale}}/articles/28919-key-activities-in-sprint?utm_source=app.howwe.io&utm_medium=web&utm_campaign=sprint_create_activity_drawer"
              linkText={t('CreateMitemDrawer.helpLinks.sprintKeyActivities')}
            />
          </div>
          <div className="pb">
            <LearnMoreLink
              urlTemplate="https://help.howwe.io/{{locale}}/articles/28918-sprint-rules-and-committing-to-a-sprint?utm_source=app.howwe.io&utm_medium=web&utm_campaign=sprint_create_activity_drawer"
              linkText={t('CreateMitemDrawer.helpLinks.sprintRules')}
            />
          </div>
          <div className="pb">
            <LearnMoreLink
              urlTemplate="https://help.howwe.io/{{locale}}/articles/28913-howwe-video-guide-acceleration-meeting?utm_source=app.howwe.io&utm_medium=web&utm_campaign=sprint_create_activity_drawer"
              linkText={t(
                'CreateMitemDrawer.helpLinks.accelerationMeetingWithSprints'
              )}
            />
          </div>
          <div className="pb">
            <LearnMoreLink
              urlTemplate="https://help.howwe.io/{{locale}}/articles/28907-howwe-video-guide-closing-and-starting-sprints"
              linkText={t('CreateMitemDrawer.helpLinks.startAndCloseSprints')}
            />
          </div>
        </>
      )}
      {error && (
        <ErrorAlert error={error} title={t('MigRelationExplorer.fetchError')} />
      )}
    </Drawer>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_MITEM_DRAWER_MITEM = gql`
  fragment CreateMitemDrawer_Mitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportsInitiatives {
      id
      domainId {
        tenantId
        teamId
        itemId
      }
    }
    supportedInitiatives {
      id
      archived
      completed {
        value
      }
      tag {
        title
        iconId
        colorCode
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner {
      id
      email
      name
      displayName
      archivedAt
    }
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
    teamId
  }
`;

export const CREATE_MITEM_V2 = gql`
  mutation createSKA($teamId: ID!, $sprintKeyActivity: SprintKaInput!) {
    createSprintKeyActivity(
      teamId: $teamId
      sprintKeyActivity: $sprintKeyActivity
    ) {
      ...CreateMitemDrawer_Mitem
    }
  }
`;
