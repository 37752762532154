import { useTranslation } from 'react-i18next';
import { MilestoneTable } from './components/milestoneTable/MilestoneTable';
import { CreateMilestoneButton } from './components/createMilestoneButton/CreateMilestoneButton';
import { gql } from '@apollo/client';
import { InitiativeProgressSection__InitiativeDetailedReportResponseFragment } from '../../../../../generated/graphql';
import { InitiativeDetailsTreeNode } from '../InitiativeDetails';

interface Props {
  report: InitiativeProgressSection__InitiativeDetailedReportResponseFragment;
  initiativeReportNode: InitiativeDetailsTreeNode;
}

export const ProgressSection = ({ report, initiativeReportNode }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mb flx flx--jc-space-between">
        <h3>{t('ProgressSection.title')}</h3>
        <div>
          <CreateMilestoneButton initiative={report.initiative} />
        </div>
      </div>

      <MilestoneTable initiativeReportNode={initiativeReportNode} />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_PROGRESS_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment InitiativeProgressSection__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    initiative {
      ...CreateMilestoneButton__Initiative2
      domainId {
        itemId
        tenantId
      }
    }
    ...MilestoneTable__InitiativeDetailedReportResponse
  }
`;
