import { match } from 'ts-pattern';
import { MitemStatus } from '../../../../../../../../generated/graphql';
import { ManageSearchIcon } from '../../../../../../../../icons/ManageSearch';
import { UpcomingEventIcon } from '../../../../../../../../icons/overviewIcons/UpcomingEventIcon';
import { friendlyDate } from '../../../../../../../../services/dateFormats';
import { Colors } from '../../../../../../../componentLibrary/Colors';
import { WarningIcon } from '../../../../../../../../icons/performanceIcons/WarningIcon';
import { CheckIcon } from '../../../../../../../../icons/overviewIcons/CheckIcon';

interface Props {
  keyActivity: {
    deadline: string;
    name: string;
    team: string;
    status: MitemStatus;
  };
}

export const MilestoneActivityTableRow = ({ keyActivity }: Props) => {
  const statusIcon = match(keyActivity)
    .with(
      { status: MitemStatus.OVERDUE },
      { status: MitemStatus.NOT_COMPLETED },
      () => (
        <WarningIcon
          style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
          className="space--r"
        />
      )
    )
    .with({ status: MitemStatus.COMPLETED }, () => (
      <CheckIcon style={{ fontSize: 15, color: Colors.Status.OK_GREEN }} />
    ))
    .with({ status: MitemStatus.PLANNED }, () => (
      <UpcomingEventIcon
        style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
        className="space--r"
      />
    ))
    .otherwise(() => '-');
  return (
    <tr>
      <td>{friendlyDate(keyActivity.deadline)}</td>
      <td>{keyActivity.name}</td>
      <td>{keyActivity.team}</td>
      <td className="text-c">{statusIcon}</td>
      <td className="text-c">
        <ManageSearchIcon style={{ fontSize: 20, color: Colors.Action.BLUE }} />
      </td>
    </tr>
  );
};
