import { gql, useSuspenseQuery } from '@apollo/client';
import {
  DurationStatus,
  GetTopInitiativeReportDocument,
} from '../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { InitiativeSectionGrid } from './InitiativeSectionGrid';
import { ToggleSection } from '../../../../team/overview/components/ToggleSection';
import { InitiativeSectionSkeleton } from './InitiativeSection.skeleton';
import { Radio } from 'antd';
import { useState } from 'react';
import { Gigantish } from '../Gigantish/Gigantish';
import { InitiativeTable } from './InitiativeTable';
import {
  MilestoneOrActiveToggle,
  MilestoneOrActiveToggleEnum,
} from '../../../initiatives/initiativeDetails/milestoneOrActivityToggle/MilestoneOrActiveToggle';

export const InitiativeSection = () => {
  const { t } = useTranslation();
  const [milestonesOrActivities, setMilestoneOrActivities] =
    useState<MilestoneOrActiveToggleEnum>(
      MilestoneOrActiveToggleEnum.Milestones
    );
  const { data } = useSuspenseQuery(GetTopInitiativeReportDocument, {
    variables: { filter: { companyInitiatives: true } },
  });
  const initiatives = data.topInitiativeReport.initiatives ?? [];

  const [overviewSectionSelection, setOverviewSectionSelection] = useState<
    'tiles' | 'timeline'
  >('tiles');

  const options = [
    { label: 'Tiles', value: 'tiles' },
    { label: 'Timeline', value: 'timeline' },
  ];

  return (
    <div>
      <h2>{t('InitiativeSection.companyInitiatives')}</h2>
      <div className="flx flx--jc-space-between flx--ai-center mt">
        <MilestoneOrActiveToggle
          value={milestonesOrActivities}
          onChange={setMilestoneOrActivities}
          disabled={overviewSectionSelection === 'timeline'}
        />
        <div>
          <Radio.Group
            options={options}
            onChange={({ target: { value } }) =>
              setOverviewSectionSelection(value)
            }
            value={overviewSectionSelection}
            optionType="button"
          />
        </div>
      </div>

      {overviewSectionSelection === 'tiles' && (
        <InitiativeSectionGrid
          milestonesOrActivities={milestonesOrActivities}
          initiatives={initiatives.filter(
            (i) => i.initiative.metadata.status === DurationStatus.ACTIVE
          )}
        />
      )}

      {overviewSectionSelection === 'timeline' && (
        <div className="mt--l">
          <Gigantish />
        </div>
      )}

      <div className="flx flx--jc-center flx--column">
        <ToggleSection
          titleExpanded={t('InitiativeSection.showLess')}
          titleNotExpanded={t('InitiativeSection.showMore')}
          data-intercom-target="Expandable section to show completed and upcoming MIGs"
          disabled={false}
        >
          <div className="flx flx--jc-space-between mb">
            <h3>{t('InitiativeSection.completedAndUpcoming')}</h3>
          </div>

          <InitiativeTable
            milestonesOrActivities={milestonesOrActivities}
            initiatives={initiatives.filter(
              (i) =>
                i.initiative.metadata.status === DurationStatus.COMPLETED ||
                i.initiative.metadata.status === DurationStatus.UPCOMING
            )}
          />
        </ToggleSection>
      </div>
    </div>
  );
};

InitiativeSection.Skeleton = InitiativeSectionSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVE_LANDING_PAGE_REPORT = gql`
  query getTopInitiativeReport($tenantId: ID, $filter: TopInitiativeFilter) {
    topInitiativeReport(tenantId: $tenantId, filter: $filter) {
      initiatives {
        id
        accumulatedStats {
          milestones {
            upcoming
          }
        }
        ...InitiativeSectionRow__TopInitiativeReport
        ...initiativeTable__TopInitiativeReport
      }
    }
  }
`;
