import {
  gql,
  PureQueryOptions,
  RefetchQueriesFunction,
  useMutation,
} from '@apollo/client';
import { Alert, Drawer, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerTitle } from '../../../../../../components/DrawerTitle';
import { SKADrawerConfirmCloseAlert } from '../../../../../../components/SKADrawerConfirmCloseAlert';
import {
  EditMItemDocument,
  EditMItemMutation,
  EditSprintKeyActivityDrawer__MitemFragment,
  MitemStatus,
} from '../../../../../../generated/graphql';
import { standardDateFormat } from '../../../../../../services/dateFormats';
import { showNotification } from '../../../../../../services/fetchNotificationProperties';
import {
  mitemStatusColorLight,
  mitemStatusColorLightContrast,
} from '../../../../../../styleVars';
import { MIG_ASSOCIATION_OTHER } from '../../SprintPlanningPage';
import { SprintKaForm } from '../SprintKaForm';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
  mitem: EditSprintKeyActivityDrawer__MitemFragment;
  showModal: boolean;
  onCompleted: (data: EditSprintKeyActivityDrawer__MitemFragment) => void;
  onCancel: () => void;
  refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
}

export const EditSprintKeyActivityDrawer = ({
  mitem,
  showModal,
  onCompleted,
  onCancel,
  refetchQueries,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [editMitem, { loading: editPending }] = useMutation(EditMItemDocument, {
    refetchQueries,
    onError: () => {
      showNotification('error', {
        message: t('EditMitemDrawer.saveError'),
      });
    },
    onCompleted: (data: EditMItemMutation) => {
      onCompleted(data.editSprintKa);
      showNotification('success', {
        message: t('EditMitemDrawer.saveSuccess'),
        description: data.editSprintKa.name,
        placement: 'top',
      });
      resetConfirmationData();
    },
  });

  // TODO: Should be changed, need to handle intercom on a higher level
  const isAccMeeting = window.location.href.includes('acceleration-meeting');

  const handleCancel = () => {
    if (formIsDirty) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      onCancel();
      form.resetFields();
    }
  };

  const resetConfirmationData = () => {
    setFormIsDirty(false);
    setShowWarning(false);
    setConfirmed(false);
    form.resetFields();
  };

  useEffect(() => {
    if (confirmed) {
      resetConfirmationData();
      onCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed]);

  useEffect(() => {
    if (!isAccMeeting) {
      (window as any).Intercom?.('update', {
        hide_default_launcher: showModal,
      });

      // sometimes the drawer is unmounted rather than getting the prop showModal={false}
      // this will make sure we show the intercom widget again in that case
      return () =>
        (window as any).Intercom?.('update', {
          hide_default_launcher: false,
        });
    }

    if (!showModal) {
      setFormIsDirty(false);
      setShowWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, isAccMeeting]);

  const handleEditMItem = (values: any) => {
    const mitemFormValues = values;
    const supportsInitiativeIds =
      (values.supportsInitiativeIds as (string | null | undefined)[]) ?? [];

    let noMigAssociation: string | null | undefined =
      mitemFormValues.noMigAssociation;

    const { supportsMigId, ...rest } = mitemFormValues;

    const mitemToSubmit =
      mitem.status === MitemStatus.COMPLETED
        ? {
            supportsInitiativeIds: supportsInitiativeIds.filter((i) => i),
            tags: mitemFormValues.tags,
            milestone: mitemFormValues.milestone,
          }
        : {
            ...rest,
            supportsMigIds:
              supportsMigId === MIG_ASSOCIATION_OTHER || supportsMigId == null
                ? []
                : [{ id: supportsMigId }],
            supportsInitiativeIds: supportsInitiativeIds.filter((i) => i),
            noMigAssociation:
              mitemFormValues.supportsMigId === MIG_ASSOCIATION_OTHER
                ? noMigAssociation
                : null,
            deadline: standardDateFormat(mitemFormValues.deadline),
          };

    editMitem({
      variables: {
        teamId: mitem.teamId,
        sprintKaId: mitem.id,
        mitem: mitemToSubmit,
      },
    });
  };

  const statusBackgroundColor = mitemStatusColorLight[mitem.status];
  const statusTextColor = mitemStatusColorLightContrast[mitem.status];

  return (
    <Drawer
      title={
        <DrawerTitle style={{ color: statusTextColor }}>
          {t(`MitemStatus.${mitem.status}`)}
        </DrawerTitle>
      }
      open={showModal}
      onClose={handleCancel}
      width={420}
      destroyOnClose
      styles={{ header: { backgroundColor: statusBackgroundColor } }}
      drawerStyle={{
        borderLeft: `8px solid ${statusBackgroundColor}`,
      }}
    >
      {showWarning && (
        <SKADrawerConfirmCloseAlert
          onConfirm={() => setConfirmed(true)}
          onClose={() => setShowWarning(false)}
        />
      )}
      {mitem.completed && (
        <Alert
          className="mb"
          message={
            <>
              <InfoCircleOutlined className="mr--s" />
              <strong>{t('EditMitemDrawer.isCompleted')}</strong>
            </>
          }
        />
      )}

      <SprintKaForm
        teamId={mitem.teamId}
        initialValues={mitem}
        submitPending={editPending}
        isEdit={true}
        onSubmit={handleEditMItem}
        onCancel={handleCancel}
        setIsFormDirty={setFormIsDirty}
        formRef={form}
      />
    </Drawer>
  );
};

export const EDIT_MITEM = gql`
  mutation editMItem($teamId: ID!, $sprintKaId: ID!, $mitem: SprintKaInput!) {
    editSprintKa(
      teamId: $teamId
      sprintKaId: $sprintKaId
      sprintKaData: $mitem
    ) {
      ...EditSprintKeyActivityDrawer__Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditSprintKeyActivityDrawerFragment = gql`
  fragment EditSprintKeyActivityDrawer__Mitem on Mitem {
    id
    teamId
    status
    completed
    supportedMigs {
      id
      domainId {
        itemId
        teamId
      }
      name
    }
    ...SprintKaForm_Mitem
  }
`;
