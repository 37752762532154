import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { DatePicker, Divider, Form, FormInstance, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import {
  Icons,
  InitiativeIcon,
} from '../../../appPages/company/initiatives_old/initiativesPageV1/InitiativeIcons';
import { ColorPicker, TagColorBox } from '../../initiative/ColorPicker';
import {
  InitiativeForm_Initiative2Fragment,
  InitiativeForm_InitiativeTagFragment,
} from '../../../generated/graphql';
import { Btn } from '../../Button';
import { InitiativeTag } from '../../initiative/InitiativeTag';
import { TextIcon } from './Icons/TextIcon';
import { PersonSearchIcon } from './Icons/PersonSearchIcon';
import { TextAreaIconIcon } from './Icons/TextAreaIcon';
import { TargetIcon } from './Icons/TargetIcon';
import { IconContainer } from '../../initiative/IconContainer';
import './InitiativeForm.less';
import { TitleIcon } from './Icons/TitleIcon';
import { AlignToMigSelector } from './AlignToMigSelector';
import { useEffect, useState } from 'react';
import { TeamMigSelector } from './TeamMigSelector';
import { AlignmentIcon } from './Icons/AlignmentIcon';
import { StartingPointIcon } from './Icons/StartingPointIcon';
import { EndingPointIcon } from './Icons/EndingPointIcon';
import { StrategicPlanAdminSelector } from './strategicPlanAdminSelector/StrategicPlanAdminSelector';
import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { InitiativeFormSkeleton } from './InitiativeForm.skeleton';

type TeamMig = {
  id: string;
  name: string;
  domainId: { itemId: string; teamId: string };
};

interface Props {
  form: FormInstance<any>;
  initialValue?: InitiativeForm_Initiative2Fragment;
  parentInitiativeTag?: InitiativeForm_InitiativeTagFragment;
  loading?: boolean;
}

export const InitiativeForm = ({
  form,
  initialValue,
  parentInitiativeTag,
  loading,
}: Props) => {
  const [showTeamSelect, setShowTeamSelect] = useState(false);
  const [teamMig, setTeamMig] = useState<TeamMig | undefined>(undefined);
  const tagIcon = (Form.useWatch('iconId', form) ??
    initialValue?.tag.iconId) as InitiativeIcon;
  const tagColor = (Form.useWatch('colorCode', form) ??
    initialValue?.tag.colorCode) as TagColorBox;
  const tagTitle = Form.useWatch('tagTitle', form) as string;
  const { t } = useTranslation();

  useEffect(() => {
    if (teamMig) {
      form.setFieldsValue({ migs: [teamMig.id] });
    }
  }, [form, teamMig]);

  const formInitialValue = initialValue
    ? {
        ...initialValue,
        tagTitle: initialValue.tag.title,
        colorCode: initialValue.tag.colorCode,
        iconId: initialValue.tag.iconId,
        objectives: initialValue.objectives.map((o) => o.text),
        startDate: initialValue.startDate
          ? dayjs(initialValue.startDate)
          : null,
        endDate: initialValue.endDate ? dayjs(initialValue.endDate) : null,
        migs: [],
      }
    : { objectives: [''] };

  return (
    <div style={{ position: 'relative' }}>
      <Form
        disabled={loading}
        layout="vertical"
        form={form}
        requiredMark={'optional'}
        initialValues={formInitialValue}
      >
        {showTeamSelect && (
          <div className="InitiativeForm__overlay">
            <TeamMigSelector
              onCancel={() => setShowTeamSelect(false)}
              onAddTeamMig={(mig) => {
                setTeamMig(mig);
                setShowTeamSelect(false);
              }}
            />
          </div>
        )}
        <div className="flx">
          <TitleIcon className="mr InitiativeForm__formIcon--singleField" />
          <Form.Item
            name="name"
            className="flx--1"
            label={t('common.title')}
            rules={[
              {
                required: true,
                message: t('InitiativeForm.titleMandatoryError'),
              },
              {
                min: 2,
                max: 51,
                message: t('InitiativeForm.titleError'),
              },
            ]}
          >
            <Input
              type="text"
              className="bold"
              style={{ fontSize: '18px' }}
              placeholder={t('InitiativeForm.titlePlaceholder')}
            />
          </Form.Item>
        </div>
        <div className="flx">
          <div className="flx flx--1">
            <TextIcon className="mr InitiativeForm__formIcon--singleField" />
            <Form.Item
              name="tagTitle"
              className="flx--1 mr--m"
              label={t('InitiativeForm.shortTitle')}
              rules={[
                {
                  required: true,
                  message: t('InitiativeForm.shortTitleError'),
                },
                {
                  min: 2,
                  max: 20,
                  message: t('InitiativeForm.tagError'),
                },
              ]}
            >
              <Input
                type="text"
                placeholder={t('InitiativeForm.tagTitlePlaceholder')}
                maxLength={21}
              />
            </Form.Item>
          </div>
          <div className="flx flx--1">
            <PersonSearchIcon className="mr InitiativeForm__formIcon--singleField" />
            <Form.Item
              name="assignedTo"
              className="flx--1 "
              label={t('InitiativeForm.ownerTitle')}
              rules={[
                {
                  required: true,
                  message: t('InitiativeForm.ownerTitle'),
                },
              ]}
            >
              <StrategicPlanAdminSelector />
            </Form.Item>
          </div>
        </div>
        <div className="flx">
          <TextAreaIconIcon className="mr InitiativeForm__formIcon--singleField" />
          <Form.Item
            name="description"
            className="flx--1"
            label={t('common.initiative.purpose')}
            rules={[
              {
                required: true,
                message: t('InitiativeForm.purposeError'),
              },
              {
                min: 2,
                max: 500,
                message: t('InitiativeForm.purposeError'),
              },
            ]}
          >
            <TextArea
              rows={4}
              showCount
              maxLength={500}
              placeholder={t('InitiativeForm.purposePlaceholder')}
            />
          </Form.Item>
        </div>

        <Form.Item
          label={
            <div style={{ marginLeft: 35 }}>
              {t('common.initiative.objectives')}
            </div>
          }
          required={false}
        >
          <Form.List name="objectives">
            {(fields, { add, remove }) => (
              <div>
                {fields.map(({ key, ...field }, index) => (
                  <div key={key} className="flx mb">
                    <TargetIcon className="InitiativeForm__formIcon mr" />
                    <Form.Item {...field} className="flx--1 mb--none mr">
                      <Input />
                    </Form.Item>
                    {index !== fields.length - 1 ? (
                      <Btn
                        type="text"
                        onClick={() => {
                          remove(field.name);
                        }}
                        icon={<DeleteOutlined />}
                      />
                    ) : (
                      <Btn
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          add();
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </Form.List>
        </Form.Item>

        <Divider />

        {!parentInitiativeTag && (
          <>
            <div className="InitiativeForm__formItem flx--jc-space-between">
              <Form.Item
                label={<div>{t('InitiativeForm.tagIcon')}</div>}
                name="iconId"
                rules={[
                  {
                    required: true,
                    message: t('InitiativeForm.iconError'),
                  },
                ]}
              >
                <IconContainer icons={Icons} />
              </Form.Item>
              <div className="flx">
                <Form.Item
                  label={t('InitiativeForm.tagColor')}
                  className="flx--1 mr--m"
                  name="colorCode"
                  rules={[
                    {
                      required: true,
                      message: t('InitiativeForm.colorError'),
                    },
                  ]}
                >
                  <ColorPicker />
                </Form.Item>
                <Form.Item
                  className="flx--1 mr--m"
                  label={t('InitiativeForm.tagPreview')}
                  required
                >
                  {/* required is set only to hide '(optional) label */}
                  <InitiativeTag
                    title={tagTitle}
                    borderColor={tagColor}
                    icon={Icons[tagIcon]}
                    // completed={}
                    // archived={initialValue?.archived}
                  />
                </Form.Item>
              </div>
            </div>
            <Divider />
          </>
        )}

        <div className="flx">
          <AlignmentIcon className="mr InitiativeForm__formIcon--singleField" />
          {parentInitiativeTag ? (
            <Form.Item
              className="flx--1 mr--m"
              label={t('InitiativeForm.alignToInitiative')}
              required
            >
              <div className="flx flx--ai-center">
                <InitiativeTag
                  title={parentInitiativeTag.title}
                  borderColor={parentInitiativeTag.colorCode}
                  icon={Icons[parentInitiativeTag.iconId as InitiativeIcon]}
                />
              </div>
            </Form.Item>
          ) : (
            <Form.Item
              className="flx--1 "
              label={t('InitiativeForm.alignToMig')}
              name="migs"
              required
            >
              <AlignToMigSelector
                teamMig={teamMig}
                onAddTeamMig={() => setShowTeamSelect(true)}
              />
            </Form.Item>
          )}
        </div>
        <Divider />
        <div className="flx">
          <div className="flx flx--1 mr">
            <StartingPointIcon className="mr InitiativeForm__formIcon--singleField" />
            <Form.Item
              className="flx--1 "
              label={t('InitiativeForm.startDate')}
              name="startDate"
              required
            >
              <DatePicker picker="date" className="fullWidth" />
            </Form.Item>
          </div>
          <div className="flx flx--1">
            <EndingPointIcon className="mr InitiativeForm__formIcon--singleField" />
            <Form.Item
              className="flx--1"
              label={t('InitiativeForm.endDate')}
              name="endDate"
              required
            >
              <DatePicker picker="date" className="fullWidth" />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

InitiativeForm.Skeleton = InitiativeFormSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_FORM__INITIATIVE_TAG = gql`
  fragment InitiativeForm_InitiativeTag on InitiativeTag {
    title
    iconId
    colorCode
  }
`;

export const INITIATIVE_FORM_EDIT = gql`
  fragment InitiativeForm_Initiative2 on Initiative2 {
    id
    name
    description
    assignedTo {
      id
      name
    }
    objectives {
      text
    }
    rev
    startDate
    endDate
    tag {
      ...InitiativeForm_InitiativeTag
    }
  }
`;
