import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { CurrentUserDocument, UserRole } from '../generated/graphql';

export const useMe = () => {
  const response = useQuery(CurrentUserDocument);

  return {
    ...response,
    isAdmin: response.data?.me.role === UserRole.ADMIN,
  };
};
export const useMeLazy = () => {
  const [load, response] = useLazyQuery(CurrentUserDocument);
  return [
    load,
    {
      ...response,
      isAdmin: response.data?.me.role === UserRole.ADMIN,
    },
  ] as const;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CURRENT_USER = gql`
  query currentUser {
    me {
      name
      initials
      id
      displayName
      email
      locale
      role
      accountSignedUpAt
      created
      teamAssociations(teamFilter: { active: true }) {
        role
        sprintAdmin
        team {
          id
          name
          accelerationDay
          teamHierarchy {
            id
          }
        }
      }
      tenant {
        id
        name
        domainId {
          tenantId
        }
      }
      intercomTerms {
        activeTermEndDate
        nextTermStartDate
      }
    }
  }
`;
