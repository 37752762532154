import './MilestoneActivitiesTable.less';
import { MitemStatus } from '../../../../../../../../generated/graphql';
import { MilestoneActivityTableRow } from './MilestoneActivityTableRow';
import { useTranslation } from 'react-i18next';
const mockData = [
  {
    keyActivity: {
      id: 1,
      deadline: '2024-05-15',
      name: 'Finish Project Proposal',
      team: 'Development Team',
      status: MitemStatus.COMPLETED,
    },
  },
  {
    keyActivity: {
      id: 2,
      deadline: '2024-05-20',
      name: 'QA Testing',
      team: 'Quality Assurance Team',
      status: MitemStatus.OVERDUE,
    },
  },
  {
    keyActivity: {
      id: 3,
      deadline: '2024-05-25',
      name: 'Client Meeting',
      team: 'Sales Team',
      status: MitemStatus.PLANNED,
    },
  },
  {
    keyActivity: {
      id: 4,
      deadline: '2024-05-30',
      name: 'Update Documentation',
      team: 'Documentation Team',
      status: MitemStatus.COMPLETED,
    },
  },
  {
    keyActivity: {
      id: 5,
      deadline: '2024-06-05',
      name: 'Training Session',
      team: 'Training Team',
      status: MitemStatus.PLANNED,
    },
  },
];

export const MilestoneActivitiesTable = () => {
  const { t } = useTranslation();
  return (
    <div className="MilestoneActivitiesTable">
      <table className="MilestoneActivitiesTable">
        <thead>
          <tr>
            <th className="text-l">{t('common.deadline')}</th>
            <th className="text-l">{t('common.activity')}</th>
            <th className="text-l">{t('common.team')}</th>
            <th className="text-c">{t('common.status')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {mockData.map((data) => (
            <MilestoneActivityTableRow
              key={data.keyActivity.id}
              keyActivity={data.keyActivity}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
