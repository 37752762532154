import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';

export enum MilestoneOrActiveToggleEnum {
  Milestones,
  Activities,
}

interface Props {
  onChange: (e: MilestoneOrActiveToggleEnum) => void;
  value: MilestoneOrActiveToggleEnum;
  disabled?: boolean;
}

export const MilestoneOrActiveToggle = ({
  onChange,
  value,
  disabled = false,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Radio.Group
      onChange={({ target: { value } }) => onChange(value)}
      value={value}
      disabled={disabled}
    >
      <Radio value={MilestoneOrActiveToggleEnum.Milestones}>
        {t('common.milestones')}
      </Radio>
      <Radio value={MilestoneOrActiveToggleEnum.Activities}>
        {t('common.activities')}
      </Radio>
    </Radio.Group>
  );
};
