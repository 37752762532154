import { Btn } from '../../../../../../components/Button';
import { useWizardNavigation } from '../../../../../../components/WizardNavigationProvider';
import { MilestoneSuggestion } from './Step2';
import { SkaDraft } from './AiSuggestButton';
import { gql, useMutation } from '@apollo/client';
import {
  Step5Demo_CreateSkaDocument,
  Step5Demo_TeamTagFragment,
  Step5Demo_UserFragment,
} from '../../../../../../generated/graphql';
import { SkaSummary } from './components/SkaSummary';
import { useTranslation } from 'react-i18next';
import { showNotification } from '../../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../../services/howweErrorParser';
import { without } from '../../../../../../services/without';

interface Props {
  teamId: string;
  milestoneSuggestion: MilestoneSuggestion;
  skaDrafts: SkaDraft[];
  tags: Record<string, Step5Demo_TeamTagFragment>;
  members: Record<string, Step5Demo_UserFragment>;
  onSave: () => void;
}

export const Step5 = (props: Props) => {
  const wizard = useWizardNavigation();

  const { t } = useTranslation();
  const [createSka, { loading: savePending }] = useMutation(
    Step5Demo_CreateSkaDocument,
    {
      onCompleted: (data) => {
        showNotification('success', {
          message: t('CreateMitemDrawer.saveSuccess'),
          description: data.createSprintKeyActivity.name,
          placement: 'top',
        });
      },
      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: t('CreateMitemDrawer.saveError'),
          description: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
      update(cache, { data }) {
        if (data?.createSprintKeyActivity) {
          cache.modify({
            fields: {
              sprintKeyActivities(existingSkaRefs) {
                const cachedSkas = existingSkaRefs.sprintKeyActivities ?? [];

                const newSkaRef = cache.writeFragment({
                  data: data.createSprintKeyActivity,
                  fragment: gql`
                    fragment NewSka on Mitem {
                      id
                    }
                  `,
                });
                return {
                  ...existingSkaRefs,
                  sprintKeyActivities: [...cachedSkas, newSkaRef],
                };
              },
            },
          });
        }
      },
    }
  );

  const handleSave = async () => {
    const promises = props.skaDrafts.map((draft) =>
      createSka({
        variables: {
          sprintKeyActivity: without(draft, '_suggestionId'),
          teamId: props.teamId,
        },
      })
    );

    await Promise.all(promises).then((_data) => {
      props.onSave();
    });
  };

  return (
    <div>
      <h3 className="mb--l">Confirm plan to achieve Milestone:</h3>
      <h4 className="mb">{props.milestoneSuggestion.area}</h4>
      <div className="font-size--sm pb--l borderBottom">
        <span className="bold space--r">Definition of done:</span>
        {props.milestoneSuggestion.description}
      </div>
      <div>
        {props.skaDrafts.map((activity, index) => (
          <SkaSummary
            activity={activity}
            tags={props.tags}
            members={props.members}
            key={index}
          />
        ))}
      </div>
      <div className="mt--l flx flx--jc-flx-end">
        <Btn
          type="link"
          onClick={wizard.goToPreviousStep}
          disabled={savePending}
        >
          Go back to edit plan
        </Btn>
        <Btn type="primary" onClick={handleSave} loading={savePending}>
          Add Key Activities to my plan
        </Btn>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_TAG_FRAGMENT = gql`
  fragment Step5Demo_TeamTag on TeamTag {
    id
    ...SkaSummary_TeamTag
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const USER_FRAGMENT = gql`
  fragment Step5Demo_User on User {
    id
    ...SkaSummary_User
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_SKA = gql`
  mutation Step5Demo_createSka(
    $teamId: ID!
    $sprintKeyActivity: SprintKaInput!
  ) {
    createSprintKeyActivity(
      teamId: $teamId
      sprintKeyActivity: $sprintKeyActivity
    ) {
      id
      ...MitemPlaningBoard_Mitem
    }
  }
`;
