import { Alert, Table } from 'antd';
import { AddInitiativeAdminSelector } from './AddInitiativeAdminSelector';
import { gql, useQuery } from '@apollo/client';
import {
  GetUsersByRoleTypeDocument,
  TenantRoleType,
} from '../../../../generated/graphql';
import { ColumnProps } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { stringSort } from '../../../../services/stringSort';
import { friendlyUsername } from '../../../../services/friendlyUsername';
import { UserAvatar } from '../../../UserAvatar';

import { DemoteAdminRoleButton } from './DemoteAdminRoleButton';
import { showNotification } from '../../../../services/fetchNotificationProperties';

type StrategicPlanAdminUser = {
  id: string;
  name: string | null;
  email: string;
  displayName: string | null;
  archived: boolean;
  initials: string;
  domainId: {
    itemId: string;
    tenantId: string;
  };
};

export const ManageStratPlanAdmins = () => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(GetUsersByRoleTypeDocument, {
    variables: { tenantRoleType: TenantRoleType.STRATEGIC_PLAN_ADMIN },
    fetchPolicy: 'cache-and-network',
  });

  const admins = data?.usersByRoleType.users ?? [];

  const columns: ColumnProps<StrategicPlanAdminUser>[] = [
    {
      title: t('common.name'),
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => stringSort(a.name, b.name),
      key: 'name',
      render: (_, record) => {
        return (
          <div className="flx">
            <UserAvatar user={record} size="small" />
            <span className="ml">{friendlyUsername(record)}</span>
          </div>
        );
      },
    },
    {
      title: t('common.email'),
      dataIndex: 'email',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => stringSort(a.email, b.email),
      key: 'email',
    },
    {
      title: t('common.actions'),
      defaultSortOrder: 'ascend',
      key: 'actions',
      render: (_, record) => {
        return (
          <DemoteAdminRoleButton
            userId={record.domainId.itemId}
            onDemote={() =>
              showNotification('success', {
                message: (
                  <div>
                    <div className="mb">
                      <strong>
                        {t(
                          'ManageStratPlanAdmins.successfullyRemovedAdminRole'
                        )}
                      </strong>
                    </div>
                    <div>
                      <UserAvatar user={record} size="small" />
                      <span className="ml">{friendlyUsername(record)}</span>
                    </div>
                  </div>
                ),
              })
            }
          />
        );
      },
    },
  ];

  return (
    <div>
      <div className="mb--xl">
        <Alert
          showIcon
          message={t('ManageStratPlanAdmins.initiativeAdminDescription')}
          closable
        />
      </div>
      <div className="mb--xl">
        <h3 className="mb">{t('ManageStratPlanAdmins.promoteAdmin')}</h3>
        <AddInitiativeAdminSelector alreadyAdmins={admins} />
      </div>
      <h3 className="mb">{t('ManageStratPlanAdmins.currentAdmins')}</h3>

      <Table
        size="middle"
        className="showScrollBar"
        rowKey={(record) => record.id}
        dataSource={admins}
        columns={columns}
        loading={loading}
        pagination={false}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_USERS = gql`
  query getUsersByRoleType($tenantId: ID, $tenantRoleType: TenantRoleType!) {
    usersByRoleType(tenantId: $tenantId, roleType: $tenantRoleType) {
      users {
        id
        name
        email
        displayName
        archived
        initials

        domainId {
          itemId
          tenantId
        }
      }
    }
  }
`;
