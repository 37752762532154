import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  InitiativeHeaderSection__InitiativeDetailedReportResponseFragment,
  InitiativeHeaderSection__SupportedMigFragment,
} from '../../../../../generated/graphql';
import { DisplayName } from '../../../../../components/DisplayName';
import { UserAvatar } from '../../../../../components/UserAvatar';
import { FinancialPotential } from './components/FinancialPotential';
import { InitiativeStatus } from '../../../../../components/initiative/InitiativeStatus';
import { InitiativeGraph } from './components/InitiativeGraph/InitiativeGraph';
import { InitiativeHeaderSectionSkeleton } from './InitiativeHeaderSection.skeleton';
type Props = {
  initiativeReport: InitiativeHeaderSection__InitiativeDetailedReportResponseFragment;
};

export const InitiativeHeaderSection = ({ initiativeReport }: Props) => {
  const { t } = useTranslation();
  const { initiative } = initiativeReport || {};
  const milestoneGraph = initiativeReport.accumulatedStats.milestones;

  const supportedMigs = initiative.metadata.supports.filter(
    (supported): supported is InitiativeHeaderSection__SupportedMigFragment =>
      supported.__typename === 'SupportedMig'
  );

  return (
    <div className="flx">
      <div className="flx--3 pr--xxl card mr--xl pa--xl">
        <h3 className="bold mb">
          {t('InitiativeHeaderSection.purposeAndObjectives')}
        </h3>
        <div className="preserveLinebreaks">{initiative.description}</div>
        <ul className="mt">
          {initiative?.objectives.map((ob, index) => (
            <li key={index} className="mb--skipLast">
              {ob.text}
            </li>
          ))}
        </ul>
      </div>
      <div className="flx--1 card pa--xl">
        <div className="mb--l">
          <h5 className="txt--secondary">
            {t('InitiativeHeaderSection.statusLabel')}
          </h5>
          <h3>
            <InitiativeStatus
              status={initiative.metadata.status}
              archived={initiative.metadata.archived}
            />
          </h3>
        </div>
        <div className="mb--xl">
          <InitiativeGraph
            height={100}
            periodData={milestoneGraph.graph}
            legendGoal={milestoneGraph.total}
            legendCompleted={milestoneGraph.completed}
          />
        </div>
        <div className="mb--l">
          <h5 className="txt--secondary mb--xs">
            {t('InitiativeHeaderSection.financialValueLabel')}
          </h5>
          <FinancialPotential initiative={initiative} />
        </div>
        <div className="mb--l">
          <h5 className="txt--secondary mb">
            {t('InitiativeHeaderSection.accountable')}
          </h5>
          {initiative?.assignedTo.map((assignee) => (
            <div key={assignee.id}>
              <span className="mr">
                <UserAvatar user={assignee} size="small" />
              </span>
              <DisplayName user={assignee} />
            </div>
          ))}
        </div>
        <div className="mb--l">
          <h5 className="txt--secondary mb">
            {t('InitiativeHeaderSection.supportsMig')}
            {supportedMigs.map((supported) => (
              <div key={supported.item.id}>{supported.item.name}</div>
            ))}

            {supportedMigs.length === 0 && <div>--</div>}
          </h5>
        </div>

        <div>
          <h5 className="txt--secondary mb">
            {t('InitiativeHeaderSection.detailedData')}
          </h5>
          <div className="flx flx--jc-space-between">
            {t('InitiativeHeaderSection.subInitiatives')}
            <div>{initiativeReport.accumulatedStats.subInitiatives.total}</div>
          </div>
          <div className="flx flx--jc-space-between">
            {t('InitiativeHeaderSection.startDate')}
            <div>{initiative.startDate ?? '--'}</div>
          </div>
          <div className="flx flx--jc-space-between">
            {t('InitiativeHeaderSection.endDate')}
            <div>{initiative.endDate ?? '--'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

InitiativeHeaderSection.Skeleton = InitiativeHeaderSectionSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_HEADER_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment InitiativeHeaderSection__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    accumulatedStats {
      subInitiatives {
        total
      }

      milestones {
        completed
        total
        graph {
          date
          completed
          total
        }
      }
    }
    initiative {
      domainId {
        itemId
        tenantId
      }
      name
      description
      assignedTo {
        id
        name
        email
        displayName
        initials
      }
      startDate
      endDate
      id
      objectives {
        text
        completed
      }
      tag {
        title
        iconId
        colorCode
      }
      metadata {
        status
        completedAt
        archived

        supports {
          ... on SupportedMig {
            ...InitiativeHeaderSection__SupportedMig
          }
        }
      }
      ...FinancialPotential_Initiative2
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SUPPORTED_MIG_FRAGMENT = gql`
  fragment InitiativeHeaderSection__SupportedMig on SupportedMig {
    type
    item {
      id
      name
    }
  }
`;
