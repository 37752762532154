import { Popover, Typography } from 'antd';
import './InitiativeCard.less';
import { CheckIcon } from '../../../icons/overviewIcons/CheckIcon';
import { UpcomingEventIcon } from '../../../icons/overviewIcons/UpcomingEventIcon';
import { WarningIcon } from '../../../icons/performanceIcons/WarningIcon';
import { Colors } from '../../../appPages/componentLibrary/Colors';
import { ManageSearchIcon } from '../../../icons/ManageSearch';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import { InitiativePreviewGraph } from './InitiativePreviewGraph';
import { GantishPopoverContent } from './GantishPopoverContent';
import { Suspense } from 'react';
import { MilestoneOrActiveToggleEnum } from '../../../appPages/company/initiatives/initiativeDetails/milestoneOrActivityToggle/MilestoneOrActiveToggle';

interface Props {
  name: string;
  domainId: {
    itemId: string;
    tenantId: string;
  };
  accumulatedStats: {
    milestones: {
      completed: number;
      overdue: number;
      upcoming: number;
      graph: {
        date: string;
        completed: number;
        total: number;
      }[];
    };
    activities: {
      completed: number;
      overdue: number;
      upcoming: number;
      graph: {
        date: string;
        completed: number;
        total: number;
      }[];
    };
    subInitiatives: {
      total: number;
    };
  };
  milestonesOrActivities: MilestoneOrActiveToggleEnum;
}

export const InitiativeCard = ({
  name,
  domainId,
  accumulatedStats,
  milestonesOrActivities,
}: Props) => {
  const stats =
    milestonesOrActivities === MilestoneOrActiveToggleEnum.Milestones
      ? accumulatedStats.milestones
      : accumulatedStats.activities;

  return (
    <Popover
      content={
        <Suspense fallback={<GantishPopoverContent.Skeleton />}>
          <GantishPopoverContent initiativeDomainId={domainId} />
        </Suspense>
      }
      placement="bottom"
      mouseEnterDelay={0.6}
      overlayStyle={{
        width: '100%',
        paddingLeft: 30,
        paddingRight: 30,
        maxWidth: 1200,
      }}
    >
      <Link
        to={`initiatives/${domainId.itemId}`}
        className="InitiativesCard__card flx--column flx--jc-space-between"
      >
        <div>
          <div className="flx flx--jc-space-between">
            <div>
              <Typography.Title level={5} className="mb--none">
                {name}
              </Typography.Title>
              {accumulatedStats.subInitiatives.total > 0 && (
                <div className="txt--secondary">
                  + Sub-initiatives ({accumulatedStats.subInitiatives.total})
                </div>
              )}
            </div>
            <div className="flx flx--ai-center">
              <ManageSearchIcon
                style={{ fontSize: 20, color: Colors.Action.BLUE }}
              />
            </div>
          </div>
        </div>
        <div className="flx flx--jc-space-between">
          <div className="InitiativeCard__milestone flx flx-align-self-end ">
            <div className=" mr flx flx--ai-center ">
              <CheckIcon
                style={{ fontSize: 15, color: Colors.Status.OK_GREEN }}
              />
              <div className="font-size--lg txt--primary space--l">
                {stats.completed}
              </div>
            </div>
            <div className="mr flx flx--ai-center">
              <WarningIcon
                style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
                className="space--r"
              />
              <div className="font-size--lg txt--primary space--l">
                {stats.overdue}
              </div>
            </div>
            <div className="mr flx flx--ai-center txt--primary">
              <UpcomingEventIcon
                style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
                className="space--r"
              />
              <div className="font-size--lg txt--primary space--l">
                {stats.upcoming}
              </div>
            </div>
          </div>
          <div style={{ width: '150px' }}>
            <InitiativePreviewGraph
              periodData={stats.graph}
              showLegends={false}
              height={40}
            />
          </div>
        </div>
      </Link>
    </Popover>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_CARD__INITIATIVE_REPORT_LIGHT = gql`
  fragment InitiativeCard__TopInitiativeReport on TopInitiativeReport {
    initiative {
      domainId {
        itemId
        tenantId
      }
      name
      description
      startDate
      endDate
      id
      tag {
        title
        iconId
        colorCode
      }
      metadata {
        completedAt
        archived
        status
      }
    }
    accumulatedStats {
      milestones {
        completed
        overdue
        upcoming
        graph {
          date
          completed
          total
        }
      }
      activities {
        completed
        overdue
        upcoming
        graph {
          date
          completed
          total
        }
      }
      subInitiatives {
        total
      }
    }
  }
`;
