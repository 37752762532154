import { Modal, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { ExpandArrow } from '../../../../../../../components/ExpandArrow';
import { gql } from '@apollo/client';
import { MilestoneStatus } from '../../../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { WarningIcon } from '../../../../../../../icons/performanceIcons/WarningIcon';
import { Colors } from '../../../../../../componentLibrary/Colors';
import { CheckIcon } from '../../../../../../../icons/overviewIcons/CheckIcon';
import { UpcomingEventIcon } from '../../../../../../../icons/overviewIcons/UpcomingEventIcon';
import { MilestoneActivitiesTable } from './components/MilestoneActivitiesTable';
import { friendlyDate } from '../../../../../../../services/dateFormats';
import { match } from 'ts-pattern';
import { MilestoneActions } from './components/milestoneActions/MilestoneActions';
import { useMilestoneModal } from './hooks/useMilestoneModal';
import { Suspense } from 'react';
import { EditMilestone } from './components/milestoneActions/components/EditMilestone';
import { Btn } from '../../../../../../../components/Button';
import { MilestoneDetails } from './components/milestoneActions/components/MilestoneDetails';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../../../../components/ErrorPage';
import { InitiativeDetailsTreeNode } from '../../../InitiativeDetails';

interface Props {
  initiativeReportNode: InitiativeDetailsTreeNode;
}

type MilestoneInfoWithStats = {
  milestone: Milestone;
  stats: { completed: number; overdue: number; upcoming: number };
  initiativeDetails: {
    id: string;
    name: string;
    domainId: {
      itemId: string;
      tenantId: string;
    };
  };
};

type Milestone = {
  id: string;
  domainId: {
    itemId: string;
    tenantId: string;
  };
  name: string;
  deadline: string;
  metadata: {
    completedAt: string | null;
    status: string;
  };
};

export const MilestoneTable = ({ initiativeReportNode }: Props) => {
  const { t } = useTranslation();
  const modalHandler = useMilestoneModal();

  const milestonesWithStats =
    initiativeReportNode.data.accumulatedMilestones.milestones;

  const milestoneColumns: ColumnProps<MilestoneInfoWithStats>[] = [
    {
      title: t('common.deadline'),
      width: 120,
      key: 'deadline',
      dataIndex: ['milestone', 'deadline'],
      render: (deadline, milestoneWithStats) => {
        const status = match(milestoneWithStats.milestone.metadata)
          .with({ status: MilestoneStatus.COMPLETED }, () => (
            <div className="bold" style={{ color: Colors.Status.OK_GREEN }}>
              {t('common.milestone.status.completed')}
            </div>
          ))
          .with({ status: MilestoneStatus.OVERDUE }, () => (
            <div className="bold" style={{ color: Colors.Status.OVERDUE }}>
              {t('common.milestone.status.overdue')}
            </div>
          ))
          .with({ status: MilestoneStatus.UPCOMING }, () => (
            <div
              className="bold"
              style={{ color: Colors.Status.FUTURE_PURPLE }}
            >
              {t('common.milestone.status.upcoming')}
            </div>
          ))
          .otherwise(() => '');

        return (
          <div className="text-c">
            {friendlyDate(deadline)}
            {status}
          </div>
        );
      },
    },
    {
      title: t('MilestoneTable.milestoneColumn'),
      key: 'name',
      dataIndex: ['milestone', 'name'],
      render: (text, milestoneInfoWithStats) => {
        return (
          <div>
            <div>{text}</div>
            <div className="txt--secondary h5">
              {milestoneInfoWithStats.initiativeDetails.name}
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <CheckIcon style={{ fontSize: 15, color: Colors.Status.OK_GREEN }} />
      ),
      key: 'completed',
      width: 90,
      dataIndex: ['stats', 'completed'],
    },
    {
      title: (
        <WarningIcon
          style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
          className="space--r"
        />
      ),
      key: 'overdue',
      dataIndex: ['stats', 'overdue'],
      width: 90,
    },
    {
      title: (
        <UpcomingEventIcon
          style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
          className="space--r"
        />
      ),
      key: 'upcoming',
      width: 90,
      dataIndex: ['stats', 'upcoming'],
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      render: (_, milestoneReport) => {
        return (
          <MilestoneActions
            milestoneId={milestoneReport.milestone.domainId.itemId}
            openModal={modalHandler.openModal}
          />
        );
      },
    },
    Table.EXPAND_COLUMN,
  ];

  const sharedModalProps = {
    footer: null,
    destroyOnClose: true,
    onCancel: modalHandler.closeModal,
  };

  return (
    <div>
      <Modal
        open={modalHandler.activeModal?.type === 'edit'}
        {...sharedModalProps}
      >
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <Suspense fallback={<EditMilestone.Skeleton />}>
            <EditMilestone
              milestoneId={modalHandler.activeModal?.milestoneId!}
            />
          </Suspense>
        </ErrorBoundary>
      </Modal>
      <Modal
        {...sharedModalProps}
        open={modalHandler.activeModal?.type === 'details'}
        title={'Milestone Details'}
        footer={
          <Btn
            type="primary"
            onClick={() => {
              modalHandler.openModal({
                type: 'edit',
                milestoneId: modalHandler.activeModal?.milestoneId!,
              });
            }}
          >
            Edit Milestone
          </Btn>
        }
      >
        <Suspense fallback={<EditMilestone.Skeleton />}>
          <MilestoneDetails
            milestoneId={modalHandler.activeModal?.milestoneId!}
          />
        </Suspense>
      </Modal>
      <Table
        columns={milestoneColumns}
        dataSource={milestonesWithStats}
        style={{ borderRadius: '6px' }}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: () => <MilestoneActivitiesTable />,
          expandIcon: ({ expanded, onExpand, record }) => (
            <ExpandArrow
              onClick={(e) => onExpand(record, e)}
              expanded={expanded}
            />
          ),
        }}
        pagination={false}
        rowKey={(milestoneReport) => milestoneReport.milestone.id}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_TABLE__MILESTONE_REPORT = gql`
  fragment MilestoneTable__MilestoneReport on MilestoneReport {
    stats {
      completed
      overdue
      upcoming
    }
    milestone {
      id
      deadline
      name
      metadata {
        status
      }
      domainId {
        itemId
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_TABLE__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment MilestoneTable__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    milestones {
      ...MilestoneTable__MilestoneReport
    }
    allSubInitiatives {
      milestones {
        ...MilestoneTable__MilestoneReport
      }
    }
  }
`;
