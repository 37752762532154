import { gql, useSuspenseQuery } from '@apollo/client';
import { FormInstance } from 'antd';
import { GetTenantBusinessPlanInitiativeForEditDocument } from '../../../../../../../generated/graphql';
import { InitiativeForm } from '../../../../../../../components/initiatives/InitiativeForm/InitiativeForm';

interface Props {
  form: FormInstance;
  initiativeId: string;
}

export const EditInitiativeModalContent = ({ form, initiativeId }: Props) => {
  const { data } = useSuspenseQuery(
    GetTenantBusinessPlanInitiativeForEditDocument,
    { variables: { initiativeId: initiativeId }, fetchPolicy: 'no-cache' }
  );

  return <InitiativeForm form={form} initialValue={data.tenantInitiative} />;
};

EditInitiativeModalContent.Skeleton = InitiativeForm.Skeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_EDIT_INITIATIVE_CONTENT = gql`
  query getTenantBusinessPlanInitiativeForEdit(
    $tenantId: ID
    $initiativeId: ID!
  ) {
    tenantInitiative(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      ...InitiativeForm_Initiative2
    }
  }
`;
