import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { ArchiveOutlinedIcon } from '../../../../../../../../../icons/ArchiveIcon';
import { CheckIcon } from '../../../../../../../../../icons/overviewIcons/CheckIcon';
import { Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../../../../../../components/Button';
import { SearchOutlineIcon } from '../../../../../../../../../icons/initiativeIcons/SearchOutlineIcon';
import { MilestoneModalState } from '../../hooks/useMilestoneModal';

type MenuItem = Required<MenuProps>['items'][number];

interface Props {
  milestoneId: string;
  openModal: (modalState: MilestoneModalState) => void;
}

export const MilestoneActions = ({ milestoneId, openModal }: Props) => {
  const { t } = useTranslation();

  const items: MenuItem[] = [
    {
      key: 'ShowDetails',
      label: <div className="txt--action-blue">{t('common.showDetails')}</div>,
      icon: <SearchOutlineIcon className="txt--action-blue" />,
      onClick: () => openModal({ milestoneId, type: 'details' }),
    },
    {
      label: <div className="txt--action-blue">{t('common.edit')}</div>,
      key: 'EditInitiative',
      icon: <EditOutlined className="txt--action-blue" />,
      onClick: () => openModal({ milestoneId, type: 'edit' }),
    },
    {
      label: (
        <div className="txt--action-blue">
          {t('common.archive')} (not implemented)
        </div>
      ),
      key: 'ArchiveInitiative',
      icon: <ArchiveOutlinedIcon className="txt--action-blue" />,
    },
    {
      label: (
        <div className="txt--action-blue">
          {t('MilestoneActions.markedAsCompleted')} (not implemented)
        </div>
      ),
      key: 'MarkAsComplete',
      icon: <CheckIcon className="txt--action-blue" />,
    },
  ];

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown menu={{ items }}>
        <Btn type="text" icon={<MoreOutlined />} />
      </Dropdown>
    </div>
  );
};
