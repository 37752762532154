import {
  createAuth0Client,
  IdToken,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  LogoutOptions,
  Auth0ClientOptions,
  Auth0Client,
} from '@auth0/auth0-spa-js';
import React, { useContext, useEffect, useState } from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserRole } from './generated/graphql';
import { VerifyEmail } from './VerifyEmail';
import { Btn } from './components/Button';
import { envConf } from './envConf';

const CUSTOM_CLAIM_PREFIX = 'https://howwe.io/';
const removePrefix = (key: string) => key.substring(CUSTOM_CLAIM_PREFIX.length);

type AuthError = {
  error: string;
  error_description: string;
};

interface TokenUser {
  howwe: { tenantId: string; role: UserRole; userId: string };
  email: string;
  email_verified: boolean;
}

interface Context {
  isAuthenticated: boolean;
  user?: TokenUser;
  loading: boolean;
  getIdTokenClaims: () => Promise<IdToken | undefined>;
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
  getTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<any>;
  logout: (options?: LogoutOptions) => void;
}

interface Props {
  children: React.ReactNode;
  onRedirectCallback: (appstate?: any) => void;
}

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<Context>({} as Context);
export const useAuth0 = () => useContext(Auth0Context);

const auth0Options: Auth0ClientOptions = {
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  domain: envConf.AUTH0_DOMAIN,
  clientId: envConf.AUTH0_CLIENT_ID,
  useFormData: false, // backwards compatibility with v1. Using form data might be quicker, but not sure if it'll affect our actions. See https://github.com/auth0/auth0-spa-js/blob/main/MIGRATION_GUIDE.md#applicationx-www-form-urlencoded-is-used-by-default-instead-of-applicationjson
  authorizationParams: {
    audience: envConf.AUTH0_AUDIENCE,
    redirect_uri: window.location.origin,
  },
};

export const Auth0Provider: React.FC<Props> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
}) => {
  const { t } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState<Auth0Client | null>(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState<AuthError | null>(null);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(auth0Options);
      setAuth0(auth0FromHook);
      // https://github.com/auth0/auth0-spa-js/issues/335
      if (
        (window.location.search.includes('code=') &&
          !window.location.search.includes('code=success')) ||
        window.location.search.includes('error=')
      ) {
        try {
          const data = await auth0FromHook.handleRedirectCallback();

          onRedirectCallback(data.appState);
        } catch (e) {
          setAuthError(e as AuthError);
          window.history.replaceState({}, document.title, '/');
        }
      }

      const authenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(authenticated);

      if (authenticated) {
        const user = await auth0FromHook.getUser();

        if (user) {
          const transformedUser = { howwe: {} as any } as any;
          for (let [key, value] of Object.entries(user)) {
            if (key.startsWith(CUSTOM_CLAIM_PREFIX)) {
              transformedUser.howwe[removePrefix(key)] = value;
            } else {
              transformedUser[key] = value;
            }
          }

          setUser(transformedUser);
        }
      }

      setLoading(false);
    };

    initAuth0();
    // eslint-disable-next-line
  }, []);

  const isSignUp = window.location.pathname.startsWith('/signup');

  useEffect(() => {
    if (!loading && !isAuthenticated && !authError) {
      if (isSignUp) {
        auth0Client!.loginWithRedirect({
          authorizationParams: { mode: 'signup' },
        });
      } else {
        const targetUrl = window.location.pathname;

        auth0Client!.loginWithRedirect({
          appState: { targetUrl },
          authorizationParams: {
            mode: 'login',
          },
        });
      }
    }
    // DO NOT LISTEN TO ESLINT RULE HERE, isSignUp should not be added.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isAuthenticated, authError]);
  const error = authError as AuthError | null;
  const accessDenied = error?.error === 'access_denied';
  const needEmailVerification =
    accessDenied && error.error_description.includes('NeedEmailVerify');
  // console.log(JSON.stringify(authError, null, 2));
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        getIdTokenClaims: () => auth0Client!.getIdTokenClaims(),
        loginWithRedirect: (...p) => auth0Client!.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client!.getTokenSilently(...p),
        logout: (...p) =>
          auth0Client!.logout({
            logoutParams: { returnTo: window.location.origin, ...p },
          }),
      }}
    >
      {!accessDenied && children}
      {needEmailVerification && (
        <VerifyEmail
          auth0Client={auth0Client}
          email={error.error_description.replace('NeedEmailVerify:', '')}
        />
      )}

      {accessDenied && !needEmailVerification && (
        <div className="flx flx--ai-center flx--column ">
          <Result
            status="403"
            title={t('Auth0Provider.notAuthorizedError')}
            subTitle={t('Auth0Provider.genericError')}
          />

          <Btn
            style={{ width: '160px' }}
            onClick={() =>
              auth0Client!.logout({
                logoutParams: { returnTo: window.location.origin },
              })
            }
          >
            {t('Auth0Provider.retry')}
          </Btn>
        </div>
      )}
    </Auth0Context.Provider>
  );
};
