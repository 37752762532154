import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { TeamResourceRequest } from './PermissionChecker';
import {
  GetGlobalPermissionsDocument,
  GetTenantPermissionsDocument,
  GetTeamPermissionsDocument,
  GetUserPermissionsDocument,
  GetTeamMemberPermissionsDocument,
  UpdateUserTenantRolesDocument,
  UpdateUserGlobalRolesDocument,
  UpdateUserPermissionsAdminDocument,
  TenantRoleType,
  GetUserPermissionRolesDocument,
  GetMyPermissionRolesDocument,
} from './generated/graphql';

export const SUPER_ADMIN_ROLE_ID = 'f4a7b4af-f6ab-44aa-957c-2f5b65cd5327';

export const useUpdateAuthTenantUser = () => {
  return useMutation(UpdateUserTenantRolesDocument);
};

export const useUpdateAuthGlobalUser = () => {
  return useMutation(UpdateUserGlobalRolesDocument);
};

export const useOldUpdateUserPermission = () => {
  return useMutation(UpdateUserPermissionsAdminDocument);
};

export const useUserPermissionRoles = (tenantId: string, userId: string) => {
  const { loading, error, data } = useQuery(GetUserPermissionRolesDocument, {
    variables: { tenantId, userId },
  });

  return {
    authUser: data?.userPermissionRoles,
    globalRoles: data?.userPermissionRoles.globalRoles,
    tenantRoles: data?.userPermissionRoles.tenantRoles,
    isSuperAdmin: data?.userPermissionRoles.globalRoles?.some(
      (r) => r.id === SUPER_ADMIN_ROLE_ID
    ),
    isTenantAdmin: data?.userPermissionRoles?.tenantRoles?.some(
      (r) => r.roleType === TenantRoleType.TENANT_ADMIN
    ),
    error,
    loading,
  };
};

export const useMyPermissionRoles = () => {
  const { loading, error, data } = useQuery(GetMyPermissionRolesDocument);

  return {
    authUser: data?.myPermissionRoles,
    globalRoles: data?.myPermissionRoles.globalRoles,
    tenantRoles: data?.myPermissionRoles.tenantRoles,
    isSuperAdmin: data?.myPermissionRoles.globalRoles?.some(
      (r) => r.id === SUPER_ADMIN_ROLE_ID
    ),
    isTenantAdmin: data?.myPermissionRoles.tenantRoles?.some(
      (r) => r.roleType === TenantRoleType.TENANT_ADMIN
    ),
    error,
    loading,
  };
};

export const useGlobalPermissionsLazy = () => {
  const [fetchGlobalPermissions, { loading, error, data }] = useLazyQuery(
    GetGlobalPermissionsDocument
  );

  return [
    fetchGlobalPermissions,
    {
      permissionsGlobal: data?.permissionsGlobal.permissions,
      error,
      loading,
    },
  ] as const;
};

export const useTenantPermissionsLazy = () => {
  const [fetchTenantPermissions, { loading, error, data }] = useLazyQuery(
    GetTenantPermissionsDocument
  );

  return [
    fetchTenantPermissions,
    {
      permissionsTenant: data?.permissionsTenant.permissions,
      error,
      loading,
    },
  ] as const;
};

export const useTeamPermissions = (
  teamResourceOwner: Omit<TeamResourceRequest, 'type'>
) => {
  const { data, error, loading } = useQuery(GetTeamPermissionsDocument, {
    variables: {
      teamId: teamResourceOwner.teamId,
      tenantId: teamResourceOwner.tenantId,
    },
  });

  const resourceRequests = Array.isArray(teamResourceOwner.requestedAction)
    ? teamResourceOwner.requestedAction
    : [teamResourceOwner.requestedAction];

  const isAllowed = resourceRequests.some((resourceRequest) => {
    const actions = Array.isArray(resourceRequest.action)
      ? resourceRequest.action
      : [resourceRequest.action];
    return actions.some((action) =>
      data?.permissionsTeam?.permissions[resourceRequest.resource]?.includes(
        action
      )
    );
  });
  return {
    permissionsTeam: data?.permissionsTeam.permissions,
    isAllowed,
    error,
    loading,
  };
};

export const useTeamPermissionsLazy = () => {
  const [fetchTeamPermissions, { loading, error, data }] = useLazyQuery(
    GetTeamPermissionsDocument
  );

  return [
    fetchTeamPermissions,
    {
      permissionsTeam: data?.permissionsTeam.permissions,
      error,
      loading,
    },
  ] as const;
};

export const useUserPermissionsLazy = () => {
  const [fetchUserPermissions, { loading, error, data }] = useLazyQuery(
    GetUserPermissionsDocument
  );

  return [
    fetchUserPermissions,
    {
      permissionsUser: data?.permissionsUser.permissions,
      error,
      loading,
    },
  ] as const;
};

export const useTeamMemberPermissionsLazy = () => {
  const [fetchTeamMemberPermissions, { loading, error, data }] = useLazyQuery(
    GetTeamMemberPermissionsDocument
  );

  return [
    fetchTeamMemberPermissions,
    {
      permissionsTeamMember: data?.permissionsTeamMember.permissions,
      error,
      loading,
    },
  ] as const;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_GLOBAL_PERMISSIONS = gql`
  query getGlobalPermissions {
    permissionsGlobal {
      id
      permissions {
        ...usePermissions__PermissionGlobal
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_GLOBAL_PERMISSIONS_FRAGMENT = gql`
  fragment usePermissions__PermissionGlobal on PermissionGlobal {
    globalRole
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_PERMISSIONS = gql`
  query getTenantPermissions($tenantId: ID) {
    permissionsTenant(tenantId: $tenantId) {
      id
      permissions {
        ...usePermissions__PermissionTenant
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_PERMISSIONS_FRAGMENT = gql`
  fragment usePermissions__PermissionTenant on PermissionTenant {
    tenant
    tenantInsights
    tenantPublicData
    tenantLinks
    tenantRole
    tenantAiTagLinks
    milestone
    initiative
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_PERMISSIONS = gql`
  query getTeamPermissions($tenantId: ID, $teamId: ID!) {
    permissionsTeam(tenantId: $tenantId, teamId: $teamId) {
      id
      permissions {
        ...usePermissions__PermissionTeam
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_PERMISSIONS_FRAGMENT = gql`
  fragment usePermissions__PermissionTeam on PermissionTeam {
    accelerationMeeting
    mig
    sprintKA
    team
    teamTag
    weeklyKA
    initiative
    achievement
    teamLinks
    teamInternalData
    teamInitiative
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_USER_PERMISSIONS = gql`
  query getUserPermissions($tenantId: ID, $userId: ID!) {
    permissionsUser(tenantId: $tenantId, userId: $userId) {
      id
      permissions {
        ...usePermissions__PermissionUser
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_USER_PERMISSIONS_FRAGMENT = gql`
  fragment usePermissions__PermissionUser on PermissionUser {
    authUser
    user
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_MEMBER_PERMISSIONS = gql`
  query getTeamMemberPermissions($tenantId: ID, $teamId: ID!, $userId: ID!) {
    permissionsTeamMember(
      tenantId: $tenantId
      teamId: $teamId
      userId: $userId
    ) {
      id
      permissions {
        ...usePermissions__PermissionTeamMember
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_MEMBER_PERMISSIONS_FRAGMENT = gql`
  fragment usePermissions__PermissionTeamMember on PermissionTeamMember {
    achievementInAllPeriods
    achievement
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const USER_ROLE_FRAGMENT = gql`
  fragment UserRolesFragment on AuthUserResponse {
    id
    globalRoles {
      id
      name
      description
    }
    tenantRoles {
      id
      name
      tenantId
      roleType
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_USER_ROLES = gql`
  query getUserPermissionRoles($tenantId: ID!, $userId: ID!) {
    userPermissionRoles(tenantId: $tenantId, userId: $userId) {
      ...UserRolesFragment
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MY_ROLES = gql`
  query getMyPermissionRoles {
    myPermissionRoles {
      ...UserRolesFragment
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_USER_ROLES = gql`
  mutation updateUserTenantRoles(
    $tenantId: ID!
    $userId: ID!
    $tenantRoleIds: [RoleInput!]!
  ) {
    updateUserTenantRoles(
      tenantId: $tenantId
      userId: $userId
      tenantRoleIds: $tenantRoleIds
    ) {
      ...UserRolesFragment
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_USER_GLOBAL_ROLES = gql`
  mutation updateUserGlobalRoles(
    $tenantId: ID!
    $userId: ID!
    $globalRoleIds: [RoleInput!]!
  ) {
    updateUserGlobalRoles(
      tenantId: $tenantId
      userId: $userId
      globalRoleIds: $globalRoleIds
    ) {
      ...UserRolesFragment
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OLD_UPDATE_PERMISSIONS_ADMIN = gql`
  mutation updateUserPermissionsAdmin(
    $tenantId: ID!
    $userId: ID!
    $userDelta: UserInputV2!
  ) {
    updateUserV2(tenantId: $tenantId, userId: $userId, userDelta: $userDelta) {
      id
      name
      displayName
      email
      role
    }
  }
`;
