import { Divider, Timeline, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  MitemStatus,
  SprintKeyActivityUserSection_MitemSprintFragment,
} from '../../../../../../../../../../generated/graphql';
import './SprintKeyActivityUserSection.less';
import { KeyActivitySection } from '../../common/KeyActivitySection';
import { mitemStatusColor } from '../../../../../../../../../../styleVars';
import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';
import { friendlyDate } from '../../../../../../../../../../services/dateFormats';
import moment from 'moment';
import { MitemTag } from '../../../../../../../../../../components/MitemStatus';
import { progressColor } from '../../../../../../../../../../components/akpi/teamCommitmentFulfillment/TeamCommitmentFulfillmentChart';

import { AvatarColumn } from '../../common/AvatarColumn';
import { gql } from '@apollo/client';

interface Props {
  user: {
    id: string;
    name: string | null;
    displayName: string | null;
    email: string;
    initials: string;
  };
  filteredSprintKeyActivities: {
    id: string;
    name: string;
    deadline: string;
    note: string | null;
    committed: boolean;
    status: MitemStatus;
    definitionOfDone: string | null;
    achievements: {
      id: string;
      achieved: string;
      completed: boolean;
      description: string | null;
    }[];
  }[];
  expandReports: boolean;
  sprint?: SprintKeyActivityUserSection_MitemSprintFragment | null;
}

export const SprintKeyActivityUserSection = ({
  user,
  filteredSprintKeyActivities,
  expandReports,
  sprint,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="flx UserKeyActivitySection">
      <AvatarColumn user={user} />
      <div style={{ width: '100%' }}>
        {filteredSprintKeyActivities.map((ska, index) => (
          <div key={ska.id}>
            <KeyActivitySection
              title={ska.name}
              note={ska.note}
              numberOfReports={ska.achievements.length}
              deadline={ska.deadline}
              status={
                sprint?.locked && ska.status === MitemStatus.PLANNED ? (
                  <MitemTag mitemStatus={MitemStatus.POSTPONED} />
                ) : (
                  <MitemTag mitemStatus={ska.status} />
                )
              }
              expandReports={expandReports}
            >
              <>
                <div className="SprintKeyActivityUserSection__definitionOfDone mt--xl mb--xl">
                  <span className="bold">
                    {t('SprintKeyActivityUserSection.definitionOfDone')}
                  </span>
                  <div className="mt">{ska.definitionOfDone}</div>
                </div>

                <Timeline>
                  {ska.achievements.map((achievement, index) => (
                    <Timeline.Item
                      key={`${index}`}
                      dot={
                        <div>
                          {achievement.completed ? (
                            <div style={{ color: mitemStatusColor.COMPLETED }}>
                              <CheckCircleFilled />
                            </div>
                          ) : (
                            <div style={{ color: progressColor.achieved }}>
                              <CheckCircleOutlined />
                            </div>
                          )}
                        </div>
                      }
                    >
                      <>
                        <div className="flx flx--jc-space-between">
                          <Typography.Text strong>
                            {achievement.completed
                              ? t('SprintKeyActivityUserSection.completed')
                              : t('SprintKeyActivityUserSection.progressed')}
                          </Typography.Text>
                          <Typography.Text type="secondary">
                            <span title={friendlyDate(achievement.achieved)}>
                              {moment(achievement.achieved).fromNow()}
                            </span>
                          </Typography.Text>
                        </div>
                        {achievement.description ? (
                          <Typography.Text type="secondary">
                            {achievement.description}
                          </Typography.Text>
                        ) : (
                          <Typography.Text type="secondary" className="italic">
                            {t('SprintKeyActivityUserSection.noComment')}
                          </Typography.Text>
                        )}
                      </>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </>
            </KeyActivitySection>
            {index !== filteredSprintKeyActivities.length - 1 && (
              <Divider className="mt--s mb--s" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_SUMMARY = gql`
  fragment SprintKeyActivityUserSection_MitemSprint on MitemSprint {
    id
    locked
  }
`;
