import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './genericCard.less';

export type GenericCardProps = {
  heading: string;
  title: string;
  children: React.ReactNode;
  keyValue?: string | number;
  backSide?: {
    description: string;
    why: string;
  };
};

export const GenericInsightCard = ({
  heading,
  title,
  backSide,
  keyValue,
  children,
}: GenericCardProps) => {
  const [genericCard, setGenericCard] = useState<Element | null>();
  const [styles, setStyles] = useState({});
  const [rotate, setRotate] = useState(false);
  const { t } = useTranslation();
  const card = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setGenericCard(card.current);
  }, [card]);

  useEffect(() => {
    if (genericCard) {
      setStyles({
        width: genericCard.getBoundingClientRect().width,
        height: genericCard.getBoundingClientRect().height,
      });
    }
  }, [genericCard]);
  return (
    <div className="GenericCard" style={styles} key={keyValue}>
      <div className="CardContent" style={styles}>
        <div
          className={`${cx(
            'GenericCard__front flx flx--column flx--jc-space-between',
            { turn: rotate }
          )}`}
          style={styles}
          ref={card}
        >
          <div className="GenericCard__main">
            <p className="GenericCard__heading flx flx--jc-space-between">
              <span>{heading}</span>
              <span
                className="GenericCard__heading--flipIcon"
                onClick={() => setRotate(true)}
              >
                <InfoCircleOutlined />
              </span>
            </p>
            <p className="GenericCard__title">
              <b>{title}</b>
            </p>
            <div className="GenericCard__text">
              <span className="genericCard--text">{children}</span>
            </div>
          </div>
        </div>

        <div
          className={`${cx('GenericCard__back', { turn: rotate })}`}
          style={styles}
        >
          <div className="GenericCard__main fullHeight flx flx--column">
            <div className="GenericCard__heading flx flx--row-reverse flx--jc-space-between">
              <div className="GenericCard__heading--flipIcon">
                <CloseCircleOutlined onClick={() => setRotate(false)} />
              </div>
              <span>{heading}</span>
            </div>
            <div className="GenericCard__descriptions flx flx--column flx--jc-center">
              <p className="GenericCard__descriptions--title">
                {t('GenericInsights.insightDescription')}
              </p>
              <p className="GenericCard__descriptions--text">
                {backSide?.description}
              </p>
              <p className="GenericCard__descriptions--title">
                {t('GenericInsights.whyImportant')}
              </p>
              <p className="GenericCard__descriptions--text">{backSide?.why}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
