import { gql, useQuery } from '@apollo/client';
import { Divider, Select } from 'antd';
import { GetAlignToMigSelector_MigsDocument } from '../../../generated/graphql';
import { Btn } from '../../Button';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type TeamMig = {
  id: string;
  name: string;
  domainId: { itemId: string; teamId: string };
};

interface Props {
  id?: string;
  value?: string;
  onChange?: (migId: string) => void;
  teamMig?: TeamMig;
  onAddTeamMig: () => void;
  className?: string;
  disabled?: boolean;
}

export const AlignToMigSelector = ({
  id,
  value,
  onChange,
  onAddTeamMig,
  className,
  disabled,
  teamMig,
}: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { data } = useQuery(GetAlignToMigSelector_MigsDocument);
  const migs = data?.globalMigs.globalMigs || [];

  const options = [
    {
      label: <span>{t('AlignToMigSelector.companyMigs')}</span>,
      options: migs.map((m) => ({
        value: m.id,
        label: m.name,
      })),
    },
    {
      label: <span>{t('AlignToMigSelector.teamMigs')}</span>,
      exclude: !teamMig,
      options: [{ label: teamMig?.name, value: teamMig?.id }],
    },
  ];

  return (
    <Select
      id={id}
      className={className}
      options={options.filter((o) => !o.exclude)}
      value={value}
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      onChange={onChange}
      disabled={disabled}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <div className="flx flx--jc-center">
            <Btn
              className="mb"
              onClick={() => {
                onAddTeamMig();
                setOpen(false);
              }}
            >
              {t('AlignToMigSelector.addTeamMig')}
            </Btn>
          </div>
        </>
      )}
    />
  );
};

export const GET_GLOBAL_MIGS_TO_ALIGN_TO = gql`
  query GetAlignToMigSelector_Migs {
    globalMigs {
      globalMigs {
        id
        name
        domainId {
          itemId
          teamId
        }
      }
    }
  }
`;
