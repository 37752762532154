import {
  MitemStatus,
  SprintMitemTable_SkaTimeStatusFragment,
} from '../../../../../generated/graphql';
import { MitemFilterStatus } from '../components/mitemFilters/StatusFilter';
import { useQueryParams } from '../../../common/hooks/useQuery';

export const useMitemFilters = () => {
  const { queryParams, setParam } = useQueryParams();

  let mitemStatusFilter = queryParams.get('status') ?? 'ALL';

  const setMitemStatusFilter = (status: MitemFilterStatus) => {
    setParam('status', status);
  };

  const migIdFilter = queryParams.get('migId') ?? 'ALL';

  const setMigIdFilter = (migId: string) => {
    setParam('migId', migId);
  };

  const mitemOwnerIdFilter = queryParams.get('ownerId') ?? 'ALL';

  const setMitemOwnerIdFilter = (ownerId: string) => {
    setParam('ownerId', ownerId);
  };

  return {
    currentFilters: {
      status: mitemStatusFilter as MitemFilterStatus,
      ownerId: mitemOwnerIdFilter,
      migId: migIdFilter,
    },
    setFilter: {
      mitemStatus: setMitemStatusFilter,
      mitemOwnerId: setMitemOwnerIdFilter,
      migId: setMigIdFilter,
    },
  };
};

export type MitemFilters = ReturnType<typeof useMitemFilters>;

export const ownerFilterCompare = (
  ownerId: string,
  ownerIdToMatch?: string
) => {
  return (
    ownerIdToMatch == null ||
    ownerIdToMatch === 'ALL' ||
    ownerId === ownerIdToMatch
  );
};

export const migFilterCompare = (
  migIds: string[] | null | undefined,
  migIdToMatch?: string
) => {
  return (
    migIdToMatch == null ||
    migIdToMatch === 'ALL' ||
    migIds?.includes(migIdToMatch) ||
    (migIds == null && migIdToMatch === 'other')
  );
};

export const statusFilterCompare = (
  mitemStatus: MitemStatus,
  statusToMatch?: MitemFilterStatus
) => {
  return (
    statusToMatch == null ||
    statusToMatch === 'ALL' ||
    mitemStatus === statusToMatch
  );
};

export const sprintKeyActivityHasTagMatch = (
  mitem: SprintMitemTable_SkaTimeStatusFragment,
  andOrChoice: number,
  tagsToMatch?: string[] | undefined
): boolean => {
  const mitemTagsIds = mitem?.sprintKeyActivity?.tags.map((t) => t.id) ?? [];
  const initiativeTagsIds =
    mitem?.sprintKeyActivity?.supportedInitiatives.map((t) => t.id) ?? [];
  const allTagsIds = [...mitemTagsIds, ...initiativeTagsIds];
  if (!tagsToMatch || tagsToMatch.length < 1) return true;
  // AND
  if (andOrChoice === 0)
    return tagsToMatch.every((tid) => allTagsIds?.includes(tid));
  // OR
  if (andOrChoice === 1)
    return tagsToMatch.some((tid) => allTagsIds?.includes(tid));

  return false;
};
