import { gql } from '@apollo/client';
import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DurationStatus,
  InitiativeSelect__Initiative2Fragment,
} from '../../../../../generated/graphql';

interface Props extends SelectProps {
  initiatives: InitiativeSelect__Initiative2Fragment[];
  onChange?: (initiative: InitiativeSelect__Initiative2Fragment) => void;
}

export const InitiativeSelect = ({
  onChange,
  initiatives,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const options = [
    {
      label: <span>{t('InitiativeSelect.activeInitiatives')}</span>,
      options: initiatives
        .filter((i) => i.metadata.status === DurationStatus.ACTIVE)
        .map((i) => ({
          value: i.domainId.itemId,
          label: i.name,
        })),
    },
    {
      label: <span>{t('InitiativeSelect.upcomingInitiatives')}</span>,
      options: initiatives
        .filter((i) => i.metadata.status === DurationStatus.UPCOMING)
        .map((i) => ({
          value: i.domainId.itemId,
          label: i.name,
        })),
    },
  ];

  return (
    <Select
      {...props}
      onChange={(itemId) => {
        const initiative = initiatives.find(
          (i) => i.domainId.itemId === itemId
        );
        if (initiative) {
          onChange?.(initiative);
        }
      }}
      options={options}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_TABLE__TOP_INITIATIVE_REPORT = gql`
  fragment initiativeSelect__Initiative2 on Initiative2 {
    domainId {
      itemId
      tenantId
    }
    name
    id
    tag {
      title
      iconId
      colorCode
    }
    metadata {
      completedAt
      archived
      status
    }
  }
`;
