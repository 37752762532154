import React, { ReactElement } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  ReferenceLine,
  Line,
  XAxis,
  YAxis,
  DotProps,
  Tooltip,
  LineProps as BrokenLineProps,
} from 'recharts';
import { Moment } from 'moment';
import { Empty } from 'antd';
import { lineColor } from '../../styleVars';
import { friendlyDate, standardDateFormat } from '../../services/dateFormats';
import { roundToDecimals } from '../../services/roundNumbersHelper';
import { CustomTooltip } from './CustomTooltip';
import { isDefined } from '../../services/isDefined';
import { momentToDayjs } from '../../services/dateHelpers';

type LineProps = Omit<BrokenLineProps, 'ref'>; // Don't know why but if we don't remove ref it fails to compile due to type missmatch

export interface Period {
  targetDate: string;
  label?: string;
  goal?: number | null;
  actual?: number | null;
}

export interface KeyActivityGraphProps {
  lineData: Period[];
  highlightedPeriod?: string | null;
  activeDot?: string | null;
  startDate: string | Moment;
  onClick?: (periodIndex: number) => void;
  lineConf: LineProps[];
  tickTextColor?: string;
  height?: number;
  children?: React.ReactNode;
  yLabel?: string | number | ReactElement | object;
}

export const KeyActivityGraph = ({
  lineData,
  onClick,
  highlightedPeriod,
  activeDot,
  startDate,
  lineConf,
  tickTextColor,
  height = 130,
  yLabel,
  children,
}: KeyActivityGraphProps) => {
  if (lineData.length === 0)
    return (
      <div className="AkpiGraph__noDataWrapper">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );

  const firstValues = lineConf.reduce(
    (acc, lc) => ({ ...acc, [lc.dataKey as any]: 0 }),
    {}
  );

  lineData = [
    {
      ...firstValues,
      targetDate: standardDateFormat(startDate),
      label: friendlyDate(momentToDayjs(startDate)),
    },
    ...lineData,
  ];

  const baseProps: LineProps = {
    type: 'linear',
    isAnimationActive: false,
    strokeWidth: 2,
    dot: (props) => (
      <CustomDot
        {...props}
        highlightedPeriod={highlightedPeriod}
        activeDot={activeDot}
      />
    ),
  };

  const baseLabel = {
    angle: -90,
    position: 'insideLeft',
  };

  const label =
    typeof yLabel === 'object'
      ? { ...baseLabel, ...yLabel }
      : typeof yLabel === 'string'
        ? { ...baseLabel, value: yLabel }
        : yLabel;

  return (
    <div style={{ width: '100%', height }}>
      <ResponsiveContainer debounce={300}>
        <LineChart
          data={lineData}
          onClick={(data) => {
            if (!isDefined(data.activeTooltipIndex) || !onClick) return;
            onClick(data.activeTooltipIndex);
          }}
          margin={{
            top: 12,
            bottom: 28,
            left: 0,
            right: 13,
          }}
        >
          <Tooltip
            formatter={(value: any) =>
              typeof value === 'number' ? roundToDecimals(value, 2) : value
            }
            labelFormatter={(label) =>
              lineData.find((p) => p.targetDate === label)?.label
            }
            content={CustomTooltip}
          />
          <XAxis
            dataKey="targetDate"
            interval={0}
            tickSize={0}
            tick={
              <CustomAxisTick
                lineData={lineData}
                tickTextColor={tickTextColor}
              />
            }
            minTickGap={5}
            height={2}
          />
          <YAxis
            tick={{ fontSize: 12 }}
            minTickGap={5}
            width={50}
            label={label}
          />

          <ReferenceLine
            x={highlightedPeriod ?? undefined}
            stroke={lineColor.currentPeriod}
            strokeDasharray="3 3"
          />

          {lineConf.map((lineProps, i) => {
            const mergedProps = { ...baseProps, ...lineProps };
            return <Line key={i} {...mergedProps} />;
          })}

          {children}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

interface CustomAxisTickProps {
  lineData: Period[];
  tickTextColor?: string;
  [key: string]: any; // a bunch of props (e.g. x,y, index) are added by recharts (probably via cloneElement) and since we don't want to send them ourselves as props we cannot add them to the interface explicitly
}

const CustomAxisTick = ({
  lineData,
  tickTextColor = '#888',
  index,
  x,
  y,
}: CustomAxisTickProps) => {
  if (index === 0 || index === lineData.length - 1) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          fontSize="12"
          x={0}
          y={0}
          dy={16}
          textAnchor={index === 0 ? 'middle' : 'end'}
          fill={tickTextColor}
        >
          {lineData[index]?.label}
        </text>
      </g>
    );
  }
  return null;
};

interface CustomDotProps extends DotProps {
  index: number;
  dataKey: string;
  payload: {
    targetDate: string;
  };
  highlightedPeriod: string;
  activeDot?: string;
}

const CustomDot = (props: CustomDotProps) => {
  if (!props.cx || !props.cy) return null;

  if (props.payload.targetDate === props.activeDot) {
    return (
      <circle
        r={3}
        strokeWidth="1"
        stroke={props.stroke}
        fill={props.stroke}
        className="recharts-dot recharts-line-dot"
        cx={props.cx}
        cy={props.cy}
      />
    );
  }

  if (props.payload.targetDate === props.highlightedPeriod) {
    return (
      <circle
        r={5}
        strokeWidth="1"
        stroke={props.stroke}
        fill={'#ffff'}
        className="recharts-dot recharts-line-dot"
        cx={props.cx}
        cy={props.cy}
      />
    );
  }

  return null;
};
