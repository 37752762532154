import { useTranslation } from 'react-i18next';
import './GantishSection.less';
import { Col, Row } from 'antd';
import { Gantish } from '../../../../../../../components/initiatives/Gantish/Gantish';
import { gql } from '@apollo/client';
import { InitiativeDetailsTreeNode } from '../../../InitiativeDetails';
import { GantishSection__InitiativeDetailedReportResponseFragment } from '../../../../../../../generated/graphql';

interface Props {
  initiativeReportNode: InitiativeDetailsTreeNode;
  report: GantishSection__InitiativeDetailedReportResponseFragment;
}

export const GantishSection = ({ initiativeReportNode, report }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="GantishSection__gantishCard mb--l">
        <Gantish initiativeReportNode={initiativeReportNode} />
      </div>

      <Row gutter={[18, 18]} className="mb--xl">
        <Col xs={12} xl={8}>
          <div className="GantishSection__statusCards">
            <div className="card__header">
              <h4>{t('GantishSection.completed')}</h4>
              {report.accumulatedStats.milestones.completed}
            </div>
          </div>
        </Col>
        <Col xs={12} xl={8}>
          <div className="GantishSection__statusCards">
            <div className="card__header">
              <h4>{t('GantishSection.overdue')}</h4>
              {report.accumulatedStats.milestones.overdue}
            </div>
          </div>
        </Col>
        <Col xs={12} xl={8}>
          <div className="GantishSection__statusCards">
            <div className="card__header">
              <h4>{t('GantishSection.planned')}</h4>
              {report.accumulatedStats.milestones.upcoming}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GANTISH_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment GantishSection__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    accumulatedStats {
      milestones {
        completed
        overdue
        upcoming
      }
      activities {
        completed
        overdue
        upcoming
      }
    }
    ...Gantish__InitiativeDetailedReportResponse
  }
`;
