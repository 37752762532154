import { Modal, Form } from 'antd';
import { Btn } from '../../../../../../components/Button';
import { useState } from 'react';
import { InitiativeForm } from '../../../../../../components/initiatives/InitiativeForm/InitiativeForm';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import {
  CreateTopInitiativeDocument,
  SupportedEntityType,
} from '../../../../../../generated/graphql';
import { showNotification } from '../../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../../services/howweErrorParser';
import { PlusOutlined } from '@ant-design/icons';

export const CreateTopInitiativeButton = () => {
  const { t } = useTranslation();
  const [createInitiative, { loading }] = useMutation(
    CreateTopInitiativeDocument
  );
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const { description, name, iconId, colorCode, tagTitle, assignedTo } =
        values;
      const longMigIds = values.migs as string[];
      const objectives: string[] = values.objectives ?? [];

      createInitiative({
        variables: {
          initiative: {
            description,
            assignedTo: [
              {
                id: assignedTo,
              },
            ],
            objectives: objectives
              .filter((o) => !!o)
              .map((objective: string) => ({
                text: objective,
              })),
            supports: longMigIds.map((longMigId) => ({
              type: SupportedEntityType.mig,
              item: { id: longMigId },
            })),

            name: name,
            tag: {
              colorCode,
              iconId,
              title: tagTitle,
            },
          },
        },

        onCompleted: (data) => {
          showNotification('success', {
            message: (
              <strong>
                {t('common.initiative.createSuccess', {
                  name: data.createTenantInitiative.name,
                })}
              </strong>
            ),
          });
          form.resetFields();
          setVisible(false);
          history.push(
            '/company/initiatives/' +
              data.createTenantInitiative.domainId.itemId +
              ``
          );
        },
        onError: (e) => {
          const howweErrors = howweErrorParser(e);

          showNotification('error', {
            message: (
              <strong>
                <ul>
                  {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
                </ul>
              </strong>
            ),
          });
        },
      });
    });
  };
  return (
    <div>
      <Modal
        open={visible}
        maskClosable={false}
        width={766}
        style={{ top: 20 }}
        confirmLoading={loading}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        onOk={handleSubmit}
        destroyOnClose
      >
        <InitiativeForm form={form} />
      </Modal>
      <Btn icon={<PlusOutlined />} onClick={() => setVisible(true)}>
        {t('CreateTopInitiativeButton.button')}
      </Btn>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_TOP_INITIATIVE = gql`
  mutation createTopInitiative($tenantId: ID, $initiative: Initiative2Input!) {
    createTenantInitiative(tenantId: $tenantId, initiative: $initiative) {
      id
      name
      description
      domainId {
        itemId
      }
    }
  }
`;
