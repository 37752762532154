import { Form, Modal } from 'antd';

import { CreateSubinitiativeModalContent } from './CreateSubinitiativeModalContent';
import { Suspense } from 'react';

interface Props {
  visible: boolean;
  initiativeId: string;
  onClose?: () => void;
}

export const CreateSubInitiativeModal = ({
  initiativeId,
  visible,
  onClose,
}: Props) => {
  const [form] = Form.useForm();

  const handleClose = () => {
    onClose?.();
    form.resetFields();
  };
  return (
    <Modal
      open={visible}
      width={766}
      footer={null}
      style={{ top: 20 }}
      onCancel={handleClose}
      destroyOnClose
    >
      <Suspense fallback={<CreateSubinitiativeModalContent.Skeleton />}>
        <CreateSubinitiativeModalContent
          form={form}
          initiativeId={initiativeId}
          onSuccess={handleClose}
        />
      </Suspense>
    </Modal>
  );
};
