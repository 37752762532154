import { gql, useLazyQuery } from '@apollo/client';
import {
  MigStatus,
  TeamMigSelectMigFragment,
  TeamMigSelectQueryDocument,
} from '../../../../generated/graphql';
import { useEffect } from 'react';
import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props extends SelectProps {
  teamId?: string;
  statusesToInclude?: MigStatus[];
  onChange?: (mig: TeamMigSelectMigFragment) => void;
}

export const TeamMigSelect = ({
  teamId,
  statusesToInclude,
  onChange,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const [fetchMigs, { data, loading }] = useLazyQuery(
    TeamMigSelectQueryDocument
  );
  useEffect(() => {
    if (teamId) {
      fetchMigs({ variables: { teamId, statusFilters: statusesToInclude } });
    }
  }, [fetchMigs, teamId, statusesToInclude]);
  const migs = data?.teamMigs.migs ?? [];

  const options = [
    {
      label: <span>{t('TeamMigSelect.activeMigs')}</span>,
      options: migs
        .filter((m) => m.status === MigStatus.ACTIVE)
        .map((m) => ({
          value: m.domainId.itemId,
          label: m.name,
        })),
    },
    {
      label: <span>{t('TeamMigSelect.upcomingMigs')}</span>,
      options: migs
        .filter((m) => m.status === MigStatus.UPCOMING)
        .map((m) => ({
          value: m.domainId.itemId,
          label: m.name,
        })),
    },
  ];

  return (
    <Select
      {...props}
      loading={loading}
      onChange={(migId) => {
        const mig = migs.find((m) => m.domainId.itemId === migId);
        if (mig) {
          onChange?.(mig);
        }
      }}
      options={options}
    />
  );
};

// eslint-disable-next-line
const TEAM_MIG_SELECT_MIG = gql`
  fragment TeamMigSelectMig on MigV2 {
    domainId {
      itemId
      teamId
      tenantId
    }
    id
    name
    status
  }
`;

// eslint-disable-next-line
const TEAM_MIG_SELECT_QUERY = gql`
  query TeamMigSelectQuery(
    $tenantId: ID
    $teamId: ID!
    $statusFilters: [MigStatus!]
  ) {
    teamMigs(
      tenantId: $tenantId
      teamId: $teamId
      statusFilters: $statusFilters
    ) {
      migs {
        ...TeamMigSelectMig
      }
    }
  }
`;
