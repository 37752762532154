import './InitGraphLegendEntry.less';

interface Props {
  label: string;
  value?: number | null;
  type: 'goal' | 'completed';
  className?: string;
}

export const InitGraphLegendEntry = ({
  label,
  value,
  type,
  className,
}: Props) => {
  return (
    <div className={'flx flx--jc-space-between ' + className ?? ''}>
      {label}
      <span className="bold">
        {value}{' '}
        <span
          className={
            type === 'goal'
              ? 'InitGraphLegendEntry__goalDot'
              : 'InitGraphLegendEntry__completedDot'
          }
        />
      </span>
    </div>
  );
};
