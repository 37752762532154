import { MinusOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { DatePicker, Modal, Radio, Select, Spin, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayDate } from '../../../../../components/DisplayDate';
import { MitemTag } from '../../../../../components/MitemStatus';
import {
  Action,
  ColumnFieldsFragment,
  InitiativeContentFragment,
  TeamResourceType,
} from '../../../../../generated/graphql';
import { ManageSearchIcon } from '../../../../../icons/ManageSearch';
import { StarIcon } from '../../../../../icons/Star';
import { dateCompare } from '../../../../../services/dateHelpers';
import { mitemDefaultSortOrder } from '../../../../../services/sprintKeyActivityUtils';
import { stringSort } from '../../../../../services/stringSort';
import './ActivitiesProgress.less';
import { Colors } from '../../../../componentLibrary/Colors';
import { Btn } from '../../../../../components/Button';
import { usePermissionForTeams } from '../../../../../hooks/usePermissionForTeams';
import { toMap } from '../../../../../services/toMap';
import { EditSprintKeyActivityDrawerWithButton } from './activityProgress/EditSkaDrawerWithButton';
import { useInitiativePageAllowEditKeyActivityFeatureFlag } from '../../../../../hooks/featureFlagHooks';
import { CreateSprintKeyActivityDrawerWithButton } from './activityProgress/CreateSprintKeyActivityDrawerWithButton';
import { RefetchReportInfoWithButton } from './activityProgress/RefetchReportInfoWithButton';
import { SprintKeyActivityDetails } from './activityProgress/SprintKeyActivityDetails';

interface ExcludedColumnProps<T> extends ColumnProps<T> {
  excluded?: boolean;
}

interface Props {
  initiative: InitiativeContentFragment;
  lastUpdatedAt?: string;
  sprintKeyActivities?: ColumnFieldsFragment[];
  contributingTeams?: { id: string; name: string }[];
  filteredTeamId?: string;
  loading?: boolean;
}

export const ActivitiesProgress = ({
  initiative,
  lastUpdatedAt,
  sprintKeyActivities,
  contributingTeams,
  filteredTeamId,
  loading,
}: Props) => {
  const { t } = useTranslation();
  const allowToCreateAndEditSKAonInitiativePage =
    useInitiativePageAllowEditKeyActivityFeatureFlag();
  const { data, loading: loadingPermissions } = usePermissionForTeams(
    TeamResourceType.SPRINT_KA,
    Action.UPDATE
  );

  const permissionTeamMap =
    data?.permittedToPerformActionForTeam.permittedResources &&
    toMap(
      data.permittedToPerformActionForTeam.permittedResources,
      (item) => item.teamId
    );

  const [startDate, setStartDate] = useState<Dayjs | null>(() =>
    dayjs().subtract(1, 'month')
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(() =>
    dayjs().add(6, 'months')
  );
  const [onlyMilestones, setOnlyMilestones] = useState(false);

  const [selectedTeam, setSelectedTeam] = useState<string | undefined>();

  const [selectedMitem, setSelectedMitem] =
    useState<ColumnFieldsFragment | null>(null);

  const sprintKas = sprintKeyActivities ?? [];

  const filtered = sprintKas.filter((ska) => {
    const deadline = dayjs(ska.deadline);

    const isSameOrAfterStartFilter = startDate
      ? deadline.isSameOrAfter(startDate)
      : true;

    const isSameOrBeforeEndFilter = endDate
      ? deadline.isSameOrBefore(endDate)
      : true;

    const onlyMilestonesFilter = onlyMilestones ? ska.milestone : true;

    const teamFilter = selectedTeam ? ska.team.id === selectedTeam : true;

    return (
      isSameOrAfterStartFilter &&
      isSameOrBeforeEndFilter &&
      onlyMilestonesFilter &&
      teamFilter
    );
  });

  const columns: ExcludedColumnProps<ColumnFieldsFragment>[] = [
    {
      title: t('ActivitiesProgress.colStatus'),
      dataIndex: 'status',
      sorter: (a, b) => {
        return (
          mitemDefaultSortOrder.indexOf(a.status) -
          mitemDefaultSortOrder.indexOf(b.status)
        );
      },
      width: 150,
      render(value, record) {
        return <MitemTag mitemStatus={record.status} />;
      },
    },
    {
      title: t('ActivitiesProgress.colTitle'),
      dataIndex: 'name',
      sorter: (a, b) => {
        return stringSort(a.name, b.name);
      },
      render(value, record) {
        return (
          <div>
            <h4>{record.name}</h4>
            <span className="font-size--sm txt--secondary">
              {record.team.name}
            </span>
          </div>
        );
      },
    },
    {
      title: t('ActivitiesProgress.colHighlight'),
      dataIndex: 'milestone',
      sorter: (a, b) => {
        const hlDiff = Number(a.milestone) - Number(b.milestone);
        if (hlDiff !== 0) return hlDiff;
        return dateCompare(a.deadline, b.deadline);
      },
      render(value, record) {
        return (
          <span style={{ fontSize: 16 }}>
            {record.milestone ? (
              <StarIcon />
            ) : (
              <MinusOutlined style={{ color: Colors.Grays.FRAMES_AND_LINES }} />
            )}
          </span>
        );
      },
    },
    {
      title: t('ActivitiesProgress.colDeadline'),
      dataIndex: 'deadline',
      sorter: (a, b) => {
        return dateCompare(a.deadline, b.deadline);
      },
      defaultSortOrder: 'ascend',
      render(value, record) {
        return <DisplayDate date={record.deadline} />;
      },
    },
    {
      title: t('common.actions'),
      dataIndex: 'action',
      excluded:
        !allowToCreateAndEditSKAonInitiativePage ||
        data?.permittedToPerformActionForTeam.permittedResources.length === 0,
      key: 'action',
      render: (_, ska) => {
        if (loadingPermissions) return <Spin />;

        const isAllowedToEdit = !!permissionTeamMap?.[ska.team.id];
        if (isAllowedToEdit) {
          return <EditSprintKeyActivityDrawerWithButton ska={ska} />;
        }

        return (
          <Tooltip
            placement="top"
            title={t('ActivitiesProgress.noPermissionToEditSka')}
            mouseEnterDelay={0.7}
          >
            -
          </Tooltip>
        );
      },
    },
    {
      width: 64,
      render(value, record) {
        return (
          <Btn
            onClick={() => {
              setSelectedMitem(record);
            }}
            type="link"
            icon={<ManageSearchIcon style={{ fontSize: 16 }} />}
          />
        );
      },
    },
  ];

  return (
    <div>
      <h2
        className="mb--l mt--xl"
        data-intercom-target="Initiative key activity progress table title"
      >
        {t('ActivitiesProgress.title')}
      </h2>
      <div className="flx flx--jc-space-between">
        <div className="ActivitiesProgress__filters">
          <div data-intercom-target="Initiative key activity start date picker">
            <label
              className="ActivitiesProgress__filterLabel"
              htmlFor="startDate"
            >
              {t('ActivitiesProgress.startDate')}
            </label>

            <DatePicker
              id="startDate"
              value={startDate}
              onChange={setStartDate}
            />
          </div>
          <div data-intercom-target="Initiative key activity end date picker">
            <label
              className="ActivitiesProgress__filterLabel"
              htmlFor="endDate"
            >
              {t('ActivitiesProgress.endDate')}
            </label>
            <DatePicker id="endDate" value={endDate} onChange={setEndDate} />
          </div>
          <div data-intercom-target="Initiative key activity team filter">
            <label className="ActivitiesProgress__filterLabel" htmlFor="teams">
              {t('ActivitiesProgress.teams')}
            </label>
            <Select
              showSearch
              placeholder={t('common.searchTeamPlaceholder')}
              optionFilterProp="label"
              style={{ width: '255px' }}
              value={selectedTeam}
              allowClear
              onClear={() => setSelectedTeam(undefined)}
              onChange={(teamId) => setSelectedTeam(teamId)}
              options={
                contributingTeams?.map((t) => ({
                  label: t.name,
                  value: t.id,
                })) ?? []
              }
              filterOption={true}
            />
          </div>
          <div data-intercom-target="Initiative key activity resolution filter">
            <label className="ActivitiesProgress__filterLabel" htmlFor="teams">
              {t('ActivitiesProgress.resolution')}
            </label>
            <Radio.Group
              value={onlyMilestones}
              onChange={(e) => setOnlyMilestones(e.target.value)}
              buttonStyle="outline"
            >
              <Radio.Button value={true}>
                {t('ActivitiesProgress.resolutionTop')}
              </Radio.Button>
              <Radio.Button value={false}>
                {t('ActivitiesProgress.resolutionAll')}
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
        <div className="flx flx--ai-center">
          {allowToCreateAndEditSKAonInitiativePage &&
            contributingTeams &&
            contributingTeams.length > 0 && (
              <CreateSprintKeyActivityDrawerWithButton
                contributingTeams={contributingTeams}
                initiative={initiative}
              />
            )}
        </div>
      </div>
      <div>
        {lastUpdatedAt && allowToCreateAndEditSKAonInitiativePage && (
          <RefetchReportInfoWithButton
            initiativeDomainId={initiative.domainId}
            lastUpdatedAt={lastUpdatedAt}
            filteredTeamId={filteredTeamId}
            className="mb"
          />
        )}
      </div>
      <Table
        loading={loading}
        columns={columns.filter((c) => !c.excluded)}
        dataSource={filtered}
        rowKey="id"
      />
      <Modal
        open={selectedMitem != null}
        onCancel={() => setSelectedMitem(null)}
        footer={null}
        modalRender={() =>
          selectedMitem && (
            <SprintKeyActivityDetails
              sprintKeyActivity={selectedMitem}
              onClose={() => setSelectedMitem(null)}
            />
          )
        }
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const INITIATIVE_SKA_COLUMN_FIELDS = gql`
  fragment ColumnFields on Mitem {
    id
    name
    deadline
    status
    owner {
      id
      email
      name
      displayName
    }
    milestone
    team {
      id
      name
    }
    ...SprintKeyActivityDetails_Mitem
    ...EditSprintKeyActivityDrawerWithButton__Mitem
  }
`;
