import { gql, useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  GetInitiativeMilestoneDetailsDocument,
  MilestoneDetails__SupportedInitiativeFragment,
} from '../../../../../../../../../../generated/graphql';
import { DisplayDate } from '../../../../../../../../../../components/DisplayDate';
import { DisplayName } from '../../../../../../../../../../components/DisplayName';
import { InitiativeTag } from '../../../../../../../../../../components/initiative/InitiativeTag';

import { UserAvatar } from '../../../../../../../../../../components/UserAvatar';
import { milestoneStatusColor } from '../../../../../../../../../componentLibrary/Colors';
import { CheckIcon } from '../../../../../../../../../../icons/overviewIcons/CheckIcon';
import { GroupsIcon } from '../../../../../../../../../../icons/GroupsIcon';
import { PersonShieldIcon } from '../../../../../../../../../../icons/PersonShield';
import { AlignmentIcon } from '../../../../../../../../../../components/initiatives/InitiativeForm/Icons/AlignmentIcon';
import { EndingPointIcon } from '../../../../../../../../../../components/initiatives/InitiativeForm/Icons/EndingPointIcon';
import { TextAreaIconIcon } from '../../../../../../../../../../components/initiatives/InitiativeForm/Icons/TextAreaIcon';

interface Props {
  milestoneId: string;
}

export const MilestoneDetails = ({ milestoneId }: Props) => {
  const { data } = useSuspenseQuery(GetInitiativeMilestoneDetailsDocument, {
    variables: { milestoneId },
    fetchPolicy: 'network-only',
  });
  const { t } = useTranslation();
  const milestone = data.milestone;

  const supportedInitiatives = milestone.metadata.supports.filter(
    (s): s is MilestoneDetails__SupportedInitiativeFragment =>
      s.__typename === 'SupportedInitiative'
  );

  const tags = supportedInitiatives.map((si) => si.item.tag);

  return (
    <div>
      <div className="flx mb--xl bold">
        <CheckIcon className="mr--l txt--secondary font-size--lg" />
        {milestone.name}
      </div>
      <div className="flx mb--xl">
        <TextAreaIconIcon className="mr--l txt--secondary font-size--lg" />
        {milestone.description}
      </div>
      <div className="flx mb--xl">
        <EndingPointIcon className="mr--l txt--secondary font-size--lg" />
        <DisplayDate date={milestone.deadline} />
        <span
          className="ml bold"
          style={{ color: milestoneStatusColor[milestone.metadata.status] }}
        >
          {t(
            `common.milestone.status.${milestone.metadata.status.toLowerCase()}`
          )}
        </span>
      </div>
      <div className="flx mb--xl">
        <PersonShieldIcon className="mr--l txt--secondary font-size--lg" />
        {milestone.assignedTo.map((assignee) => (
          <span key={assignee.id}>
            <span className="mr--s">
              <UserAvatar user={assignee} size="small" />
            </span>
            <DisplayName user={assignee} />
          </span>
        ))}
      </div>
      <div className="flx mb--xl">
        <AlignmentIcon className="mr--l txt--secondary font-size--lg" />
        {tags.map((tag) => (
          <InitiativeTag key={tag.title} tag={tag} />
        ))}
      </div>
      <div className="flx mb--xl">
        <GroupsIcon className="mr--l txt--secondary font-size--lg" />
        Contributing teams (Not implemented)
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SUPPORTED_INITIATIVE_FRAGMENT = gql`
  fragment MilestoneDetails__SupportedInitiative on SupportedInitiative {
    item {
      id
      name
      tag {
        colorCode
        iconId
        title
      }
    }
  }
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_DETAILS_MODAL_CONTENT_QUERY = gql`
  query getInitiativeMilestoneDetails($tenantId: ID, $milestoneId: ID!) {
    milestone(tenantId: $tenantId, milestoneId: $milestoneId) {
      id
      name
      description
      deadline
      assignedTo {
        id
        email
        name
        displayName
        initials
      }
      metadata {
        status
        archived
        completedAt
        supports {
          ... on SupportedInitiative {
            ...MilestoneDetails__SupportedInitiative
          }
        }
      }
    }
  }
`;
