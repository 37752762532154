import { gql, useLazyQuery } from '@apollo/client';
import { GetMitemsDocument } from '../../../../../generated/graphql';
import { stringSort } from '../../../../../services/stringSort';
import { MitemFragment } from '../../common/components/mitemFragment';

export const useMitemsLazy = () => {
  const [fetchMitems, { data, loading, error }] = useLazyQuery(
    GetMitemsDocument,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const sortedMitems = [
    ...(data?.sprintKeyActivities.sprintKeyActivities ?? []),
  ].sort((a, b) => stringSort(a.deadline, b.deadline));

  return [fetchMitems, { loading, error, mitems: sortedMitems }] as const;
};

// This is used to generate `GetMitemsDocument`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MITEMS = gql`
  query getMitems(
    $teamId: ID!
    $startDate: String
    $endDate: String
    $archived: Boolean
  ) {
    sprintKeyActivities(
      teamId: $teamId
      archived: $archived
      startDate: $startDate
      endDate: $endDate
    ) {
      sprintKeyActivities {
        ...BaseMitem
      }
    }
  }
  ${MitemFragment}
`;
